import { Injectable } from '@angular/core';
import { ddsEnvironment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  public emailSubject = new BehaviorSubject<any>('')
  public editSubject = new BehaviorSubject<any>('')
  // public partnerId
  constructor(private http: HttpClient) {
    let partnerObj:any = JSON.parse(localStorage.getItem('partnerDetails'));
    let getpartnerID = localStorage.getItem('partnerId')
    if(!getpartnerID)
    {
      localStorage.setItem('partnerId',partnerObj?.id)
      // this.fetchPartnersList().subscribe((res:any)=>{

      //   let filterPartner= res.find(obj=>
      //     {

      //       if(obj.name.toLowerCase()==userPartnerName.toLowerCase())
      //       {
      //         return obj
      //       }
      //     })
      //   let partnerId=filterPartner?.id.toString();
        
      // })
    }

  }

  downloadAll(query) {
    if (query.partnerId && query.partnerId.length > 0) {
      return this.http.get(`${ddsEnvironment.baseUrl}/partners?output=csv-download&partnerId=${query.partnerId}`)
    } else {
      return this.http.get(`${ddsEnvironment.baseUrl}/partners?output=csv-download`)
    }
  }

  setEmail(email: any) {
    this.emailSubject.next(email)
  }

  setEdit(res: any) {
    this.editSubject.next(res)
  }

  fetchPartnersList() {
    return this.http.get(
      `${ddsEnvironment.baseUrl}/partners`)
  }

  fetchAndFilterPartnersList(query) {
    if (query.partnerId && query.partnerId.length > 0) {
      return this.http.get(
        `${ddsEnvironment.baseUrl}/partners?partnerId=${query.partnerId}`)
    } else {
      return this.http.get(
        `${ddsEnvironment.baseUrl}/partners`)
    }
  }

  fetchPartnerDetails(id) {
    return this.http.get(
      `${ddsEnvironment.baseUrl}/partners/${id}`)
  }

  fetchPartnerDeliverypointDetails(id) {
    return this.http.get(
      `${ddsEnvironment.baseUrl}/partners/${id}/deliverypoints`)
  }
  fetchPartnerDeliverypointDetailsDefaultPartnerId() {
    let partnerId=JSON.parse(localStorage.getItem('partnerDetails'))?.id;
    return this.http.get(
      `${ddsEnvironment.baseUrl}/partners/${partnerId}/deliverypoints`)
  }

  saveNewpartnerOrder(body,orderType) {
    return this.http.post(
      `${ddsEnvironment.baseUrl}/partnertool/${orderType}`, body)
  }
  saveSettingInfo(body) {
    return this.http.post(
      `${ddsEnvironment.baseUrl}/partnertool/savepartnercustomerdefaults`, body)
  }

  getPartnerInfo() {
    return this.http.get(`${ddsEnvironment.baseUrl}/partnertool/getpartnerorders`)
  }

  getsettingInfo() {
    return this.http.get(`${ddsEnvironment.baseUrl}/partnertool/getpartnercustomerdefaults`)
  }

  getPartnerVault() {
    let obj = { currentStatus: "vault" }
    return this.http.get(`${ddsEnvironment.baseUrl}/partnertool/getpartnerorders`, { params: obj })
  }

  getPartnerOrderToProcess() {
    let obj = { currentStatus: "process" }
    return this.http.get(`${ddsEnvironment.baseUrl}/partnertool/getpartnerorders`, {params: obj })
  }

  getPartnerOrderHistory() {
    let obj = { currentStatus: "history" }
    return this.http.get(`${ddsEnvironment.baseUrl}/partnertool/getpartnerorders`, {params: obj })
  }

  updatePartnerDeliveryPoints(id, body) {
    return this.http.post(
      `${ddsEnvironment.baseUrl}/partners/` + id + `/deliverypoints`, body)
  }
  fetchMaintenanceUser() {
    return this.http.get(`${ddsEnvironment.baseUrl}/maintenance/users`)
  }
  fetchMaintenanceUserById(id) {
    return this.http.get(`${ddsEnvironment.baseUrl}/maintenance/users/${id}`)
  }
  createMaintenanceUser(data) {
    return this.http.post(`${ddsEnvironment.baseUrl}/maintenance/createuser`, data)
  }
  deleteMaintenanceUser(data) {
    return this.http.delete(`${ddsEnvironment.baseUrl}/maintenance/deleteuser`, { params: data })
  }
  updateMaintenanceUser(data) {
    return this.http.patch(`${ddsEnvironment.baseUrl}/maintenance/createuser`, data)
  }

  addNewPartner(data) {
    return this.http.post(`${ddsEnvironment.baseUrl}/partners/add`, data)
  }

  editPartnerSetting(id, setting, data) {
    return this.http.post(`${ddsEnvironment.baseUrl}/partners/${id}/setting/${setting}`, data)
  }

  editPartnerSettings(id, settings) {
    return this.http.post(`${ddsEnvironment.baseUrl}/partners/${id}/setting`, settings)
  }

  editPartner(data) {
    return this.http.post(`${ddsEnvironment.baseUrl}/partners/edit`, data)
  }
  addCustomer(data) {
    return this.http.post(`${ddsEnvironment.baseUrl}/partnertool/customer/addcustomers`, data)
  }
  getCustomer(){
    return this.http.get(`${ddsEnvironment.baseUrl}/partnertool/customer/getcustomer`)
  }
  
  fetchDriverDetails(id) {
    return this.http.get(
      `${ddsEnvironment.baseUrl}/partners/${id}/drivers`)
  }
  addPartnerPostmanSchemas(id, body) {
    return this.http.put(
      `${ddsEnvironment.baseUrl}/partners/` + id + `/drivers`, body)
  }
  updatePartnerPostmanSchemas(id, body,driverId) {
    return this.http.post(
      `${ddsEnvironment.baseUrl}/partners/` + id + `/drivers/` + driverId, body)
  }
  deletePartnerPostmanSchemas(id,body) {
    return this.http.delete(
      `${ddsEnvironment.baseUrl}/partners/` + id + `/drivers/` + body.id, body)
    }
    getPartnerCustomer(custId){
      let obj = {custId:custId}
      return this.http.get(`${ddsEnvironment.baseUrl}/partnertool/customer/getcustomer`,{params:obj})
    }
  
    fetchPartnerDeliverypointDetailswithPartnerId(params:any={}) {
      let partnerId=JSON.parse(localStorage.getItem('partnerDetails'))?.id;
      return this.http.get(
        `${ddsEnvironment.baseUrl}/partners/${partnerId}/deliverypoints`,{params:params})
    }
  }