import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { PartnerService } from '../../Service/partner.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr'
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { DeliverypointsService } from 'src/app/Service/deliverypoints.service';
import * as momenttimezone from 'moment-timezone'
import { TranslateService } from '@ngx-translate/core';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from '../../Service/app.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { AuthorizationService } from 'src/app/Service/authorization.service';
import { UserService } from 'src/app/Service/user.service';
// import { element } from 'protractor';

@Component({
  selector: 'app-partner-order',
  templateUrl: './partner-order.component.html',
  styleUrls: ['./partner-order.component.scss']
})
export class PartnerOrderComponent implements OnInit {

  showSpinner: boolean = false;
  noPackage: any
  amount: any
  reference: any
  location: any
  queue: any
  reservePeriodFrom: any
  reserPeriodFromTime: any
  reservePeriodTo: any
  reserPeriodToTime: any
  barcode: any
  customerBarcode: any
  storedValue: any
  public partenOrderForm
  action: any;
  btnclick: boolean = false;
  isShow = true;
  todos: any;
  locationData: any = [];
  getEmail: any
  todayDate: any
  overviewDate: any
  tomorrowDate: any
  editResponse: any
  submitted: boolean = false;
  @ViewChild('contentToConvert') contentToConvert!: ElementRef
  @ViewChild('reserveDateToInput') reserveDateToInput: ElementRef
  selectedLocationname: any;
  id: any;
  orderToProcess: any;
  reservationCheck: boolean = true;
  editForm: boolean = false
  disablePickupCode: boolean = false
  editBarcode: boolean = false
  lockerNotFree: boolean = false;
  whentocollectvalue = '';
  partnerId: any = 1;
  email: any;
  translateData: any;
  language: any;
  customerObj: any;
  customerData: any;
  maxDate: Date;
  
  constructor(
    private FB: FormBuilder,
    public activatedRoute: ActivatedRoute,
    private partnerService: PartnerService,
    public router: Router,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private deliverypointservice: DeliverypointsService,
    private renderer: Renderer2,
    private langtransService: LangtransService,
    private appService: AppService,
    private spinner: SpinnerService,
    private _userservice:UserService
    
  ) {
    this.action = this.activatedRoute.snapshot.paramMap.get('action')
    this.route.params.subscribe((data) => {
      this.id = data
      this.disablePickupCode=this.action =='edit'? true:false;
      if (Object.keys(this.id).length != 0 && data.id) {
        this.editForm=true;
        if (this.id.action == 'process') {
          this.editForm = true
         
          this.editBarcode = false
          this.getpartnerOrderToProcess(this.id)
        }
        if (this.id.action == "vault") {
          this.editForm = true
         
          this.editBarcode = false
          this.getOrderPlacedInVault(this.id)
        }

        if (this.id.action == "history") {
          this.editForm = true
         
          this.editBarcode = false
          this.getOrderInHistory(this.id)
        }
        if (this.id.action == "ironing") {

          this.editForm = false
          this.editBarcode = true
        }
      }
    });
    if(this.action==="edit"){
      this.editResponse = this.router.getCurrentNavigation()?.extras?.state?.data;
     
      if(!this.editResponse){
        this.router.navigate(['/partnertools/newOrder']);
      }
      this.getEmail= this.editResponse?.email;
      this.customerObj={
        firstName:this.editResponse?.customers?.firstName,
        lastName:this.editResponse?.customers?.lastName ,
        Email:this.editResponse?.customers?.email ,
        phoneNumber:this.editResponse?.customers?.phoneNumber
       }
     }
  }


  ngOnInit(): void {
    this.meApiCall()
    this.partnerId = window.localStorage.getItem('partnerId') ? parseInt(window.localStorage.getItem('partnerId')) : 1;
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data
    })
    this.partnerService.editSubject.subscribe(res => {
      if(this.action !== 'edit'){
        this.editResponse = res
      }
    })
    if (this.editResponse && this.editResponse.orderDate && this.action !== 'add') {
      let orderDateval = this.editResponse.orderDate;
      // this.overviewDate = orderDateval ? orderDateval.split('Z').join('') : '';
      //this.overviewDate = this.editResponse.orderDate;
      this.overviewDate = moment.utc(orderDateval).local().format('DD-MM-YYYY hh:mm')
    } else {
      const currentTime = moment.utc().local().format('DD-MM-YYYY hh:mm');
      // let currentDateVal = momenttimezone(currentTime).tz("Europe/Berlin").format("YYYY-MM-DD HH:mm:ss");
      this.overviewDate = currentTime;
    }
    if (this.editResponse && this.action && (this.action == 'process' || this.action == 'vault' || this.action == 'edit')) {
      // displaying 'when to collect' value when action is = process,vault, edit
      let resFromMoment = null;
      let resToMoment = null;
      let resFrom = this.editResponse.reservePeriodFrom ? this.editResponse.reservePeriodFrom.split('T')[0] : '';
      resFromMoment = resFrom ? moment(resFrom, 'YYYY-MM-DD') : '';
      let resTo = this.editResponse.reservePeriodTo ? this.editResponse.reservePeriodTo.split('T')[0] : '';
      resToMoment = resTo ? moment(resTo, 'YYYY-MM-DD') : '';
      if (resFromMoment && resToMoment) {
        this.whentocollectvalue = moment(resFromMoment).format("DD-MM-YYYY") + ' - ' + moment(resToMoment).format("DD-MM-YYYY");
      } else {
        this.whentocollectvalue = resFrom + ' - ' + resTo
      }
    }
    this.todayDate = new Date();
    var todate = new Date();
    todate.setDate(todate.getDate() + 5);
    this.maxDate = todate
    var tomDate = new Date();
    tomDate.setDate(tomDate.getDate() + 1)
    // let reserveTodateDefault = tomDate
    this.tomorrowDate = tomDate
    this.partnerService.emailSubject.subscribe(res => {
      if(!res && this.action === "add"){
        this.router.navigate(['/partnertools/newOrder']);
      }
      this.getEmail = res
    })

    this.partenOrderForm = new FormGroup({
      noPackage: new FormControl(1, []),
      // amount: new FormControl('', []),
      reference: new FormControl('', []),
      location: new FormControl('', [Validators.required]),
      // queue: new FormControl('', []),
      reservePeriodFrom: new FormControl(this.todayDate, [Validators.required]),
      // reservePeriodFromTime: new FormControl('', []),
      reservePeriodTo: new FormControl(this.maxDate, [Validators.required]),
      // reservePeriodToTime: new FormControl('', []),
      barcode: new FormControl('', []),
       customerBarcode: new FormControl('', [Validators.pattern(/^[A-Z0-9]{6}$/)]),
     // customerBarcode: new FormControl('',[]),
      length: new FormControl(100, []),
      width: new FormControl(100, []),
      height: new FormControl(100, [])

      // add: this.FB.array([
      //   // new FormControl(null),
      // ])

    })
    // this.storedValue = JSON.parse(window.localStorage.getItem('storedValue'))
    // if (this.id != 0) {
    //   this.partenOrderForm.patchValue(this.storedValue[this.id - 1])
    // }
    this.setLocation()
    if (this.action == 'add') {
      if (this.action == 'add' && this.id.id) {
        this.editForm = false
        this.editBarcode = false

        this.partenOrderForm.get('barcode').setValue(this.id.id)
        this.extractValue(this.id.id)
      }
    }

    if (this.action == 'edit') {
      this.editBarcode = false
     // this.disableJnJInput=true;
      this.patchEditValue()
    }
    if (this.id.action == "ironing") {
      this.partenOrderForm.get('barcode').setValue(this.id.id)
    }
  }
meApiCall(){
  this._userservice.me().subscribe((res:any) => {
   
    if(this.action == 'add'){
      this.getSettingDetails(res.partner.slug);
    }
  })
}
  onInputChange(event: any): void {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/[^0-9]/g, '');
  }

  getSettingDetails(slug:string) {
    let dimension={} ;
    if (slug !== 'j&j'){
      this.spinner.show();
      this.partnerService.getsettingInfo().subscribe((res: any) => {
        this.spinner.hide();
        let result = res[0];
        if (result.length > 0) {
          dimension={ l:result.length ? result.length : '', w:result.width ? result.width : '', h:result.height ? result.height : ''};
          this.patchDefaultValues(dimension);
        } else {
          dimension={ l:100, w:100, h:100};
        }
      }, err => {
        console.error(err);
        this.spinner.hide();
      })
    } else {
      let parcelSize = this.id.id.match(/(MD|HG|LG)U\d+/)
      dimension =
        parcelSize[1] === "MD" ? { l: 405, w: 300, h: 610 } :
          parcelSize[1] === "HG" ? { l: 405, w: 1000, h: 610 } :
            parcelSize[1] === "LG" ? { l: 405, w: 400, h: 610 } : null
    }

    this.patchDefaultValues(dimension);

    // this.partnerService.getsettingInfo().subscribe((res: any) => {
    //   if (res.length != 0) {
    //     this.patchDefaultValues(res[0]);
    //   }
    // })
  }

  patchDefaultValues(result: any) {
    this.partenOrderForm.patchValue({
      length: result.l,
      width: result.w,
      height: result.h,
    })
  }

  patchEditValue() {
    
    this.partenOrderForm.patchValue({
      reference: this.editResponse.reference,
      location: this.editResponse.locationId,
      reservePeriodFrom: moment.utc(this.editResponse.reservePeriodFrom).local().format('DD-MM-YYYY hh:mm'),
      reservePeriodTo: moment.utc(this.editResponse.reservePeriodTo).local().format('DD-MM-YYYY hh:mm'),
      barcode: this.editResponse.barcode,
      customerBarcode: this.editResponse.pickupCode,
      length: this.editResponse['length'],
      width: this.editResponse.width,
      height: this.editResponse.height,
    })
    this.todayDate = new Date (this.editResponse.reservePeriodFrom);
    let date = new Date ()
    date.setDate(this.todayDate.getDate() + 5)
    this.maxDate = date
  }

  setLocation() {
    this.partnerService.fetchPartnerDeliverypointDetailsDefaultPartnerId().subscribe(
      (res: any) => {
        let locationKeys = Object.keys(res)
        let data = [];
        // for (let key in res) {
          for (let i=0;i<res.dpdsByAcCode.list.length;i++) {
            this.locationData.push({ id: res.dpdsByAcCode.list[i].deliveryPointId, name: res.dpdsByAcCode.list[i].lockerName,lockerCode:res.dpdsByAcCode.list[i].deliveryPointId})
          }
        // }
        if (this.action == 'edit') {
          this.setLocationName(this.editResponse.locationId);
        }
      },
      err => {

        console.error(err);
      }
    )
  }
  setLocationName(val: any) {
    let selectedlocation = this.locationData.find((element) =>

      element.lockerCode == val
    )

    this.checkLockerSpace(selectedlocation.lockerCode)
    this.selectedLocationname = selectedlocation.name;
  }

  checkLockerSpace(val: any) {
    this.deliverypointservice.fetchwithId(val, {
      includeTerminals: true,
      includeCompartments: true
    }).then((res: any) => {
      let terminals = res.terminals
      let count = 0;
      for (let i = 0; i < terminals.length; i++) {
        for (let j = 0; j < terminals[i].compartments.length; j++) {
          if (terminals[i].compartments[j].active) {
            if (terminals[i].compartments[j].status == null || terminals[i].compartments[j].status == 'free' || terminals[i].compartments[j].status == '') {
              count = count + 1;
              break
            }
          }
        }
        if (count != 0) {
          break
        }
      }
      if (count == 0) {
        this.lockerNotFree = true
      }
      else {
        this.lockerNotFree = false
      }
    })
    .catch(ex => {
      console.error(ex);
      this.lockerNotFree = false
    })
  }


  saveDetails(orderType: string) {
    this.submitted = true
    if (this.partenOrderForm.valid) {
      this.spinner.show();
      // if (this.partenOrderForm.value.customerBarcode == '') {

      //   let sixDigitAlphaNumeric = this.generateRandomString(6);
      //   //this.partenOrderForm.controls['customerBarcode'].setValue(sixDigitAlphaNumeric)
      // }

      let body: any
      if (this.action == "add") {
        let userData = JSON.parse(window.localStorage.getItem('userData'))
        let userEmail = userData.email || userData.Email
        body = {
          "pickupCode": this.partenOrderForm.value.customerBarcode,
          "noPackage": this.partenOrderForm.value.noPackage,
          "reference": this.partenOrderForm.value.reference,
          "locationId": parseInt(this.partenOrderForm.value.location),
          "reservePeriodFrom": (this.partenOrderForm.value.reservePeriodFrom).toISOString(),
          "reservePeriodTo": (this.partenOrderForm.value.reservePeriodTo).toISOString(),
          "barcode": this.partenOrderForm.value.barcode,
          "email": this.getEmail,
          "length": parseInt(this.partenOrderForm.value.length),
          "width": parseInt(this.partenOrderForm.value.width),
          "height": parseInt(this.partenOrderForm.value.height),
          "language": this.language ? this.language.toLowerCase() : window.localStorage.getItem('currentLang')
        }
      }
      else if (this.action == "edit") {
        body = {
          "partnerId": this.partnerId,
          "noPackage": this.partenOrderForm.value.noPackage,
          "reference": this.partenOrderForm.value.reference,
          "locationId": this.partenOrderForm.value.location,
          "reservePeriodFrom": this.editResponse.orderDate,
          "reservePeriodTo": (this.partenOrderForm.value.reservePeriodTo).toISOString(),
          "barcode": this.partenOrderForm.value.barcode,
          "email": this.getEmail,
          "orderId": this.editResponse.id,
          "pickupCode": this.partenOrderForm.value.customerBarcode,
          "length": this.partenOrderForm.value.length,
          "width": this.partenOrderForm.value.width,
          "height": this.partenOrderForm.value.height,
          "language": window.localStorage.getItem('currentLang')

        }
      }
      const utcToDate = moment();
      const reservePeriodTo = moment(this.partenOrderForm.value.reservePeriodTo, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
      if (moment(reservePeriodTo).isBefore(utcToDate)){
        this.spinner.hide();
        return this.toastr.error(this.translate.instant('Reservation Expiry date cannot be in the past'), this.translate.instant('sorry'));
      }
      //new trial logic for announce and reserve
      if (orderType == 'placeorder') {
        this.executePlaceOrderWorkFlow(body);
      } else {
        this.partnerService.saveNewpartnerOrder(body, orderType).subscribe((res: any) => {
          this.spinner.hide();
          if (res && res.error && res.error == 'barcodeexists') {
            return this.toastr.error(this.translate.instant('barcodeexists'), this.translate.instant('sorry'));
          }
          if (res && res.error && res.error == 'pickupcodeexists') {
            return this.toastr.error(this.translate.instant('Pick up code already used by another active order'), this.translate.instant('sorry'));
          }
          this.toastr.success(this.translate.instant('Partner order saved !!'), this.translate.instant('Success'));
          this.router.navigate(['/partnertools/newOrder']);
        },
          err => {
            this.spinner.hide();
            this.toastr.error(this.translate.instant('Failed to save partner order !!'), this.translate.instant('Failed'));
            console.error(err)
          }
        )
      }

    }
    // let formValue
    // if (this.id == 0) {
    //   if (this.storedValue) {
    //     this.storedValue[this.storedValue.length] = this.partenOrderForm.getRawValue()
    //     window.localStorage.setItem('storedValue', JSON.stringify(this.storedValue));
    //     this.router.navigate(['/partnertools']);
    //   }
    //   else {

    //     formValue = [this.partenOrderForm.getRawValue()]
    //     window.localStorage.setItem('storedValue', JSON.stringify(formValue));
    //     this.router.navigate(['/partnertools']);

    //   }


    // }

    // else {
    //   this.storedValue[this.id - 1] = this.partenOrderForm.getRawValue()
    //   window.localStorage.setItem('storedValue', JSON.stringify(this.storedValue));
    //   this.router.navigate(['/partnertools']);
    // }
  }

  todateChange(event){
    this.partenOrderForm.controls['reservePeriodTo'].setValue(event)
  }
  get add() {
    return this.partenOrderForm.controls["add"] as FormArray;
  }

  get formValidators() {
    return this.partenOrderForm.controls;
  }
  generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  // Example usage


  // addPackage() {
  //   this.btnclick = true;
  //   // (<FormArray>this.partenOrderForm.get('add')).push(new FormControl(null));
  //   const addForm = this.FB.group({
  //     package: ['', Validators.required],

  //   });
  //   this.add.push(addForm);
  // return this.partenOrderForm.get('add') as FormArray;
  // const control= new FormControl(null);
  // (<FormArray>this.partenOrderForm.get('add')).push(control);

  // }
  // deletePkg(i: number) {
  //   this.add.removeAt(i);
  //   console.log(i);

  // }
  featureHide() {
    this.isShow = false;
  }

  generatePdf() {
    if (this.selectedLocationname == undefined || this.partenOrderForm.value.barcode == '') {
      this.toastr.warning(this.translate.instant("please enter the location and barcode"), this.translate.instant("sorry"))
    }
    else {
      let element = document.getElementById('contentToConvert')
      const pdf = new jsPDF('l');
      html2canvas(element).then((canvas) => {

      });

      html2canvas(element, {
        onclone: function (clonedDoc) {
          clonedDoc.getElementById('contentToConvert').style.display = 'block';
        }
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'PNG', 10, 10, 400, 50);
        pdf.setProperties({
          title: "barcode"
        })

        // pdf.save("test")
        var fileurl = URL.createObjectURL(pdf.output("blob"))
        window.open(fileurl, '_blank')
        const link = document.createElement('a')
        link.href = fileurl;
        link.download = "barcode.pdf"
        link.click()
        // Save or open the PDF as needed
        // pdf.output('dataurlnewwindow');
        //your onrendered function code here
      })
    }
  }
  // generatePDF(){
  //   console.log("hii")
  //   const element = document.getElementById('contentToConvert');
  //   console.log(element)
  //   // Configuration for html2pdf
  //   const options = {
  //     margin: 10,
  //     filename: 'generated-document.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  //   };

  //   // Generate PDF
  //   html2pdf().from(element).set(options).outputPdf((pdf: any) => {
  //     console.log(pdf)
  //     // You can save the PDF or handle it as needed
  //     const blob = new Blob([pdf], { type: 'application/pdf' });
  //     const url = URL.createObjectURL(blob);

  //     // Open the PDF in a new tab
  //     window.open(url, '_blank');
  //   });
  // }
  getpartnerOrderToProcess(id) {
    this.partnerService.getPartnerOrderToProcess().subscribe((res: any) => {
      this.commonObject(res, id)
    })
  }
  commonObject(res, id) {
    let matchedObject
    if (id.action == 'process') {
      matchedObject = res.find(obj => obj.reserveid == id.id)
      
    } else {
      matchedObject = res.find(obj => obj.id == id.id)
    }
    if (matchedObject) {
      this.partenOrderForm.patchValue({
        noPackage: matchedObject.noPackage,
        reference: matchedObject.reference,
        location: matchedObject.locationId,
        reservePeriodFrom: moment.utc(this.editResponse.reservePeriodFrom).local().format('DD-MM-YYYY hh:mm'),
        reservePeriodTo: moment.utc(this.editResponse.reservePeriodTo).local().format('DD-MM-YYYY hh:mm'),
        barcode: matchedObject.barcode,
        customerBarcode: matchedObject.pickupCode,
        length: matchedObject.length,
        width: matchedObject.width,
        height: matchedObject.height
      })
     
      this.customerObj={
        firstName:matchedObject?.customers?.firstName,
        lastName:matchedObject?.customers.lastName,
        Email:matchedObject?.customers.email,
        phoneNumber:matchedObject?.customers.phoneNumber
       }
    this.todayDate = new Date (matchedObject.reservePeriodFrom);
    let date = new Date ()
    date.setDate(this.todayDate.getDate() + 5)
    this.maxDate = date
    }
    this.reservationCheck = moment(this.todayDate) >= moment(matchedObject.reservePeriodTo) ? true : false
    //reserve period from to date fix
    if (matchedObject && this.action && (this.action == 'process' || this.action == 'vault' || this.action == 'history' )) {
      let resFromMoment = null;
      let resToMoment = null;
      let resFrom = matchedObject.reservePeriodFrom ? matchedObject.reservePeriodFrom.split('T')[0] : '';
      resFromMoment = resFrom ? moment(resFrom, 'YYYY-MM-DD') : '';
      let resTo = matchedObject.reservePeriodTo ? matchedObject.reservePeriodTo.split('T')[0] : '';
      resToMoment = resTo ? moment(resTo, 'YYYY-MM-DD') : '';
      if (resFromMoment && resToMoment) {
        this.whentocollectvalue = moment(resFromMoment).format("DD-MM-YYYY") + ' - ' + moment(resToMoment).format("DD-MM-YYYY");
      } else {
        this.whentocollectvalue = resFrom + ' - ' + resTo
      }
    }
  }

  getOrderPlacedInVault(id) {
    this.partnerService.getPartnerVault().subscribe((res: any) => {
      this.orderToProcess = res
      this.commonObject(res, id)
      // let matchedObject = res.find(obj => obj.id == id.id)
      // console.log(matchedObject, "OBJECT")
      // if (matchedObject) {
      //   this.partenOrderForm.setValue({
      //     noPackage: matchedObject.noPackage,
      //     reference: matchedObject.reference,
      //     location: matchedObject.locationId,
      //     reservePeriodFrom: this.datepipe.transform(matchedObject.reservePeriodFrom, 'yyyy-MM-dd'),
      //     reservePeriodTo: this.datepipe.transform(matchedObject.reservePeriodTo, 'yyyy-MM-dd'),
      //     barcode: matchedObject.barcode,
      //     customerBarcode: matchedObject.pickupCode,
      //   })

      // }
      // this.reservationCheck = moment(this.todayDate) >= moment(matchedObject.reservePeriodTo) ? true : false
      // console.log("reservationCheck", this.reservationCheck)
    })
  }

  getOrderInHistory(id) {
    this.partnerService.getPartnerOrderHistory().subscribe((res: any) => {
      this.orderToProcess = res
      this.commonObject(res, id)
      // let matchedObject = res.find(obj => obj.id == id.id)
      // console.log(matchedObject, "OBJECT")
      // if (matchedObject) {
      //   this.partenOrderForm.setValue({
      //     noPackage: matchedObject.noPackage,
      //     reference: matchedObject.reference,
      //     location: matchedObject.locationId,
      //     reservePeriodFrom: this.datepipe.transform(matchedObject.reservePeriodFrom, 'yyyy-MM-dd'),
      //     reservePeriodTo: this.datepipe.transform(matchedObject.reservePeriodTo, 'yyyy-MM-dd'),
      //     barcode: matchedObject.barcode,
      //     customerBarcode: matchedObject.pickupCode,
      //   })

      // }
      // this.reservationCheck = moment(this.todayDate) >= moment(matchedObject.reservePeriodTo) ? true : false
      // console.log("reservationCheck", this.reservationCheck)
    })
  }

  executePlaceOrderWorkFlow(body: any){
    //attempt reservation
    if (!body.locationId || body.locationId == '') {
      this.spinner.hide();
      return this.toastr.warning(this.translate.instant('selectlocation'), this.translate.instant('sorry'));
      //return this.toastr.warning(`Please select 'Location'`,'Warning');
    }
    let announceBarcode = { ...body, action: 'announce', email: this.getEmail };
    this.attemptSaveOrder(announceBarcode,body)
  }
  attemptSaveOrder(announceBarcode,body){
    this.partnerService.saveNewpartnerOrder(announceBarcode, 'placeorder').subscribe((res: any) => {
      //barcode exists scenario
      if (res && res.error){
        this.spinner.hide();
        if(res.error == 'barcodeexists'){
          return this.toastr.error(this.translate.instant('barcodeexists'), this.translate.instant('sorry'));
        }
        if(res.error == 'pickupcodeexists'){
          return this.toastr.error(this.translate.instant('Pick up code already used by another active order'), this.translate.instant('sorry'));
        }
        this.toastr.error(this.translate.instant('System error'), this.translate.instant('sorry'));
      }
      // After barcode exist need to perform reservation
      else{
        this.reserveOrder(body,res)
      }
    },
      (err) => {
        this.spinner.hide();
        this.toastr.error(this.translate.instant('Failed to announce order !!'), this.translate.instant('Failed'));
        console.error(err)
      }
    )
  }
  reserveOrder(body,res){
    let reserveBarcode = { ...body, action: 'reserve', barcode: res?.barcode };
    this.partnerService.saveNewpartnerOrder(reserveBarcode, 'placeorder').subscribe((result: any) => {
      this.spinner.hide();
      if (result && result.reservationFail) {
        this.toastr.warning(this.translate.instant('Announcement success, reservation failed'), this.translate.instant('Success'));
      } else {
        this.toastr.success(this.translate.instant('Partner order saved and reserved!!'), this.translate.instant('Success'));
        this.router.navigate(['/partnertools/newOrder']);
      }
    },(err) => {
      this.spinner.hide();
      console.error(err);
      this.toastr.success(this.translate.instant('Place order initiated !!'), this.translate.instant('Success'));
    })
  }
  extractValue(barcode) {
    const regex = /U(.*?)U/
    let codeFetch = barcode.match(regex)
    this.partnerService.getPartnerCustomer(codeFetch[1]).subscribe((res: any) => {
      if (res.length == 0) {
        this.langaugeFetch(this.translateData, 'No customers were found')
        this.router.navigate(['partnertools/createBarcode'])
      }
      else {
        let idArray = res.filter(obj => obj.clientNumber == parseInt(codeFetch[1]))
        this.getEmail = idArray[0].Email
        this.language = idArray[0].Language
      }
    })
  }

  openDatePicker(v) {
    if (v == 'reserveDateToInput'){
      this.renderer.selectRootElement(this.reserveDateToInput.nativeElement).click()
    }


  }

  langaugeFetch(lang, key) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        this.toastr.error(res)
      }
    )
  }
}
