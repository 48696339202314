import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgForm, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { DatePipe } from '@angular/common';
import { DataTablesModule } from "angular-datatables";

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { QRCodeModule } from 'angularx-qrcode';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';

import { HeaderComponent } from './Components/header/header.component';
import { FooterComponent } from './Components/footer/footer.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';

//routing
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './Components/home/home.component';

import { environment, ddsEnvironment, pingEnvironment } from '../environments/environment';
import { Requestor, FetchRequestor } from '@openid/appauth';
import { AuthorizationService } from '../app/Service/authorization.service';
import { AuthenticationService } from '../app/Service/authenticaion.service';
import { LockerlistComponent } from './Components/lockers/lockerlist/lockerlist.component';

import { httpInterceptor } from './Interceptor/http';
import { LockerdetailsComponent } from './Components/lockers/lockerdetails/lockerdetails.component'
import { DataService } from './Service/data.service';
import { LogsComponent } from './Components/lockers/logs/logs.component';
import { LockersMenuComponent } from './Components/lockers/lockers-menu/lockers-menu.component';
import { TerminalsComponent } from './Components/lockers/terminals/terminals.component';
import { ParcelListComponent } from './Components/parcels/parcel-list/parcel-list.component';
import { ParcelDetailsComponent } from './Components/parcels/parcel-details/parcel-details.component';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ReservationComponent } from './Components/reservation/reservation.component';
import { LoaderComponent } from './Components/tools/loader/loader.component';
import { UserlistComponent } from './Components/users/userlist/userlist.component';
import { UserDetailsComponent } from './Components/users/user-details/user-details.component';

import { HasPermissionDirective } from './hasPermission.directive';
import { ForbiddenUserComponent } from './Components/users/forbidden-user/forbidden-user.component';
import { UserDetailsPreviewComponent } from './Components/users/user-details-preview/user-details-preview.component';
import { UserDetailsUpdateComponent } from './Components/users/user-details-update/user-details-update.component';

import { SignInComponent } from './Components/sign-in/sign-in.component';
import { PingBpostComponent } from './Components/callback/ping-bpost/ping-bpost.component';
import { PingExternalComponent } from './Components/callback/ping-external/ping-external.component';
import { PartnerListComponent } from './Components/partners/partner-list/partner-list.component';
import { PartnerDetailsComponent } from './Components/partners/partner-details/partner-details.component';
import { PartnerNavigationMenu } from './Components/partners/partner-navigation-menu/partner-navigation-menu.component';
import { PartnerInfoMenu } from './Components/partners/partner-info-menu/partner-info-menu.component';
import { PartnerEditTimesComponent } from './Components/partners/partner-edit-times/partner-edit-times.component';
import { PartnerEditInfoComponent } from './Components/partners/partner-edit-info/partner-edit-info.component';
import { PartnerEditPostmanCodesComponent } from './Components/partners/partner-edit-postman-codes/partner-edit-postman-codes.component';
import { PartnerEditPostmanComponent } from './Components/partners/partner-edit-postman/partner-edit-postman.component';

import { PartnerEditDeliverypointsComponent } from './Components/partners/partner-edit-deliverypoints/partner-edit-deliverypoints.component';
import { ConnectivityLogsComponent } from './Components/lockers/connectivity-logs/connection-logs.component';
import { LockersLayoutComponent } from './Components/lockers/lockers-layout/lockers-layout.component';
import { NumbersOnlyDirective } from './Components/lockers/lockerlist/numbers-only.directive';
import { PartnerToolsComponent } from './Components/partner-tools/partner-tools.component';
import { PartnerHomeComponent } from './Components/partner-home/partner-home.component';
import { PartnerNewOrderComponent } from './Components/partner-new-order/partner-new-order.component';
import { PartnerOrdertoProcessComponent } from './Components/partner-orderto-process/partner-orderto-process.component';
import { PartnerOrderVaultComponent } from './Components/partner-order-vault/partner-order-vault.component';
import { PartnerHistoryComponent } from './Components/partner-history/partner-history.component';
import { PartnerCreateNewOrderComponent } from './Components/partner-create-new-order/partner-create-new-order.component';
import { PartnerOrderComponent } from './Components/partner-order/partner-order.component';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { FileUploadModule } from 'ng2-file-upload';
import { LockerUploadComponent } from './Components/lockers/locker-upload/locker-upload.component'
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatSelectFilterModule } from 'mat-select-filter';

import { OrderModule, OrderPipe } from 'ngx-order-pipe';
import { ViewControllerDirective } from './view-controller.directive';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { PostmanLoginCodeComponent } from './modals/postman-login-code/postman-login-code.component';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { PostmanCodeComponent } from './Components/postman-code/postman-code.component';
import { UsergroupComponent } from './Components/users/usergroup/usergroup.component';
import { UsergroupaddeditComponent } from './Components/users/usergroupaddedit/usergroupaddedit.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LockerdownreportComponent } from './Components/lockerdownreport/lockerdownreport.component';
import { ConfirmationComponent } from './modals/confirmation/confirmation.component';
import { FmFailedReportComponent } from './Components/fm-failed-report/fm-failed-report.component';
import { UseroperationsComponent } from './Components/useroperations/useroperations.component';
import { UnAuthorizedpageComponent } from './Components/un-authorizedpage/un-authorizedpage.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanggtoggleComponent } from './Components/langgtoggle/langgtoggle.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MaintenanceStaffComponent } from './maintenance-staff/maintenance-staff.component';
import { MaintenanceStaffListComponent } from './maintenance-staff-list/maintenance-staff-list.component';
import { MaintenanceStaffUpdateComponent } from './maintenance-staff-update/maintenance-staff-update.component';
import { MatIconModule } from '@angular/material/icon';
import { PartnerCustomerComponent } from './partner-customer/partner-customer.component';
import { PartnerAddComponent } from './Components/partners/partner-add/partner-add.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { LengthLimitDirective } from './length-limit.directive'
import { NgxBarcodeModule } from 'ngx-barcode';
import { PartnerSettingsComponent } from './Components/partner-settings/partner-settings.component';
import { IroningBarcodeComponent } from './Components/ironing-barcode/ironing-barcode.component';
import { SettingsComponent } from './Components/lockers/settings/settings.component';
import { CameraComponent } from './Components/lockers/camera/cameraoperations.component';


import { UtcToLocalDate } from './utctolocaldate.pipe';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    DashboardComponent,
    LockerlistComponent,
    LockerdetailsComponent,
    LogsComponent,
    LockersMenuComponent,
    TerminalsComponent,
    ParcelListComponent,
    ParcelDetailsComponent,
    ReservationComponent,
    LoaderComponent,
    UserlistComponent,
    UserDetailsComponent,
    HasPermissionDirective,
    ForbiddenUserComponent,
    UserDetailsPreviewComponent,
    UserDetailsUpdateComponent,
    SignInComponent,
    PingBpostComponent,
    PingExternalComponent,
    PartnerListComponent,
    PartnerDetailsComponent,
    PartnerNavigationMenu,
    PartnerInfoMenu,
    PartnerEditDeliverypointsComponent,
    PartnerEditPostmanCodesComponent,
    PartnerEditPostmanComponent,
    PartnerEditTimesComponent,
    PartnerEditInfoComponent,
    ConnectivityLogsComponent,
    LockersLayoutComponent,
    NumbersOnlyDirective,
    ViewControllerDirective,
    PartnerToolsComponent,
    PartnerHomeComponent,
    PartnerNewOrderComponent,
    PartnerOrdertoProcessComponent,
    PartnerOrderVaultComponent,
    PartnerHistoryComponent,
    PartnerCreateNewOrderComponent,
    PartnerOrderComponent,
    LockerUploadComponent,
    PostmanLoginCodeComponent,
    MultiSelectDropdownComponent,
    PostmanCodeComponent,
    UsergroupComponent,
    UsergroupaddeditComponent,
    LockerdownreportComponent,
    ConfirmationComponent,
    FmFailedReportComponent,
    UseroperationsComponent,
    UnAuthorizedpageComponent,
    LanggtoggleComponent,
    MaintenanceStaffComponent,
    MaintenanceStaffListComponent,
    MaintenanceStaffUpdateComponent,
    PartnerCustomerComponent,
    PartnerAddComponent,
    LengthLimitDirective,
    PartnerSettingsComponent,
    IroningBarcodeComponent,
    SettingsComponent,
    CameraComponent,
    SettingsComponent,
    UtcToLocalDate
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    QRCodeModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    DataTablesModule,
    NgbTooltipModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    NgxBarcodeModule,
    MatSortModule,
    NgxSliderModule,
    MatCardModule,
    OrderModule,
    MatIconModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatButtonToggleModule,
    MatInputModule,
    MatSelectFilterModule,
    MatSelectModule,
    BsDatepickerModule.forRoot()
  ],
  providers: [
    AuthorizationService,
    AuthenticationService,
    { provide: Requestor, useValue: new FetchRequestor() },
    { provide: 'AuthorizationConfig', useValue: environment },
    { provide: 'AuthorizationConfigExternal', useValue: pingEnvironment },
    { provide: HTTP_INTERCEPTORS, useClass: httpInterceptor, multi: true },
    DataService,
    DatePipe,
    BsModalService,
    TranslateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}
