import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ParcelService } from '../../../Service/parcel.service';
import { DeliverypointsService } from '../../../Service/deliverypoints.service';
import * as $ from 'jquery'
import { Options, LabelType, ChangeContext } from "@angular-slider/ngx-slider";
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { AppService } from '../../../Service/app.service';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { utcFormatter } from '../../timeFormatter';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
export class NgxQrCode {
  text: string;
}

@Component({
  selector: 'app-parcel-details',
  templateUrl: './parcel-details.component.html',
  styleUrls: ['./parcel-details.component.scss']
})
export class ParcelDetailsComponent implements OnInit {

  loading: boolean
  id: any
  body: any
  data: any
  result: any
  activity: Array < any > = [];
  barcode: any
  href: any;
  type: boolean
  showSpinner: boolean

  changeParcelStatuses: any;
  bdeStatus: any;
  druStatus: any;
  changeParcelStatus: any;

  minimunExpirationDate: string;

  // Variables for changing compartments
  changeParcelCompartmentReadyness: boolean;
  cpcDpd: any;
  currentLocker: any; // This is the full information of where this parcel is
  // currently phisically availableç
  currentCompartment: any;
  currentTerminal: any;
  changeCompartmentOpts: any;
  currentParcelStatus: any;


  public qrdata: string = null;
  public parcelForm;
  public parcelDetailsForm;

  public expiryRequestForm;
  public showPickupInstruction = false;

  dateRange: Date[]
  lowValue: number
  highValue: number
  historicalDetails: any = []
  isEligibleForExpiryUpdate = false;
  iscancelled = false
  eligibleForAssignComparment = false;
  isEligibleForProcessManually = true;
  startDate: number
  stepArrayData: any
  isEligibleForChangeCompartment = false;
  sliderOptions: Options = {}

  parcelType: any;
  isFraud: boolean;
  fraudbutton: boolean;
  postmanDroppedItem: any;
  postmanDroppedItemCode: any;
  activityName: any;
  pickupCode: any;
  parcelCurrentFlow: any;
  fmandlmCheckCode: any;
  fmandlmSubCode: any;
  public copyBtn: string = 'Copy';
  maxchar: number = 150;
  isLockerEmpty: boolean = false;
  delivery: any;
  collected: any;
  isReserved = false;
  isFirstMileReserved = false;
  showUnauthorized: boolean = false;
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;
  @ViewChild('qr', { static: false }) qr: ElementRef;
  @ViewChild('changeExpiry', { static: false }) changeExpiry: ElementRef;
  @ViewChild('processManual', { static: false }) processManual: ElementRef;
  @ViewChild('triggerFraud', { static: false }) triggerFraud: ElementRef;
  @ViewChild('resendEmail', { static: false }) resendEmail: ElementRef;
  @ViewChild('optionCancel', { static: false }) optionCancel: ElementRef;
  bde23Status: boolean;
  bdu1Status: boolean;
  ace2Status: boolean;
  dru1Status: boolean;
  nde12Status: boolean;
  toolTipval: any
  newDate: any;
  lockerFetchfail: any;
  translateData: any;
  Postmanexpiredpickup: any;
  Pickedupbypostman: any;
  Pickedupbycustomer: any;
  isEligible: boolean;
  histDetails: any;
  showOption: boolean = true;
  partnerName: any;

  constructor(
    private langtransService: LangtransService,
    private appService: AppService,
    public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private ParcelService: ParcelService,
    private DeliverypointsService: DeliverypointsService,
    private datePipe: DatePipe,
    private spinner: SpinnerService,
    private translate: TranslateService,
    private router: Router,
    private location: Location
  ) {
    this.bdeStatus = {
      flow: 'LM',
      eventCode: 'BDE',
      eventSubCode: '23',
      description: 'Delivered by postman'
    };
    this.druStatus = {
      flow: 'FM',
      eventCode: 'DRU',
      eventSubCode: '1',
      description: 'Delivered by customer'
    };
  }

  loadCurrentLocker(deliverypoint: any, itemBarcode: any): Promise < void > {
    return this.DeliverypointsService.fetchwithId(deliverypoint.id, {
        includeTerminals: true,
        includeCompartments: true
      })
      .then(currentDds => {
        this.currentLocker = currentDds

        this.currentLocker.terminals.map(t => {
          this.currentCompartment = t.compartments.find(c => {
            return c.itemBarcode === itemBarcode
          })

          if (this.currentCompartment) {
            this.currentTerminal = t
          }
        })
      })
  }

  langaugeFetch(lang, key) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (key == 'Postmanexpiredpickup')
          this.Postmanexpiredpickup = res
        if (key == 'Pickedupbypostman')
          this.Pickedupbypostman = res
        if (key == 'Pickedupbycustomer')
          this.Pickedupbycustomer = res
        this.changeParcelStatuses = [{
            flow: 'LM',
            eventCode: 'NDE',
            eventSubCode: '12',
            description: this.Postmanexpiredpickup
          },
          {
            flow: 'LM',
            eventCode: 'BDU',
            eventSubCode: '1',
            description: this.Pickedupbycustomer
          },
          {
            flow: 'FM',
            eventCode: 'ACE',
            eventSubCode: '2',
            description: this.Pickedupbypostman
          },
        ]
      }
    )
  }

  changeParcelCompartment(): void {
    // Check the parcel is currently assigned to a compartment:
    if (!this.result.dds) return;
    let parcelId = parseInt(this.id)

    this.loadCurrentLocker(this.result.dds.deliverypoint, this.result.barcode)
      .then(() => {
        this.changeCompartmentOpts = this.currentLocker.terminals.map(t => {

          let label = `TERMINAL ${t.id} (${t.externalId || 'no external id'})`
          // if (!t.active) label += ` (inactive)`
          t.compartments.sort((a, b) =>
            a.number - b.number);
          return {
            label: label,
            disabled: !t.active,
            compartments: t.compartments.map(c => {
              let label = `COMPARTMENT N. ${c.number}  (${c.size})`

              if (c.itemBarcode) label += ` (${c.itemBarcode})`
              if (!c.active) label += ` (inactive)`
              if (c.itemBarcode === this.result.barcode) label += ` (this same parcel)`

              return {
                value: c.id,
                label: label,
                disabled: !c.active || c.itemBarcode === this.result.barcode
              }
            })
          }
        })
      })
  }

  changeParcelCompartmentTrigger(compartmentId: any): void {
    compartmentId = parseInt(compartmentId)
    let parcelId = this.id
    let selectedTerminal = this.currentLocker.terminals.find(t => {
      let cSpotted = t.compartments.find(c => c.id === compartmentId)
      return cSpotted
    })

    let terminalId;
    let status

    if (!selectedTerminal) return;

    terminalId = selectedTerminal.id
    status = this.currentCompartment.status

    this.setLoading(true)
    this.ParcelService.changeCompartment(this.id, {
        terminalId,
        compartmentId,
        status
      })
      .then(res => {
        this.toastr.success(this.translate.instant('Compartment changed correctly'), this.translate.instant('Success'))
        this.ngOnInit()
      })
      .catch(err => {
        this.toastr.error(this.translate.instant('Error changing the parcel compartment'), this.translate.instant('Failed'));
      })
      .finally(() => {
        this.setLoading(false)
        this.loadCurrentLocker(this.result.dds.deliverypoint, this.result.barcode)
      })
  }

  setLoading(loading: boolean): void {
    this.loading = loading
    if (loading === false) {
      this.spinner.hide();
    } else {
      this.spinner.show();
    }
  }

  ngAfterViewInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.changeParcelStatuses = [{
          flow: 'LM',
          eventCode: 'NDE',
          eventSubCode: '12',
          description: this.translate.instant('Postman expired pick up')
        },
        {
          flow: 'LM',
          eventCode: 'BDU',
          eventSubCode: '1',
          description: this.translate.instant('Picked up by customer')
        },
        {
          flow: 'FM',
          eventCode: 'ACE',
          eventSubCode: '2',
          description: this.translate.instant('Picked up by postman')
        },
      ]
      this.checkValidChangeParcelStatusDropdwon()
      this.computeEligibilityForExpiryUpdate()
    });

    let divElement = this.myDiv.nativeElement;
    let divstyle = window.getComputedStyle(divElement)
    let display = divstyle?.display;
    if (display == 'none') {
      this.showUnauthorized = true
    }

    let qr = this.qr.nativeElement;
    let qrliststyle = window.getComputedStyle(qr)
    let qrdisplay = qrliststyle?.display;

    let changeExpiry = this.changeExpiry.nativeElement;
    let changeExpiryliststyle = window.getComputedStyle(changeExpiry)
    let changeExpirydisplay = changeExpiryliststyle?.display;

    let processManual = this.processManual.nativeElement;
    let processManualliststyle = window.getComputedStyle(processManual)
    let processManualdisplay = processManualliststyle?.display;

    let triggerFraud = this.triggerFraud.nativeElement;
    let triggerFraudliststyle = window.getComputedStyle(triggerFraud)
    let triggerFrauddisplay = triggerFraudliststyle?.display;

    let resendEmail = this.resendEmail.nativeElement;
    let resendEmailliststyle = window.getComputedStyle(resendEmail)
    let resendEmaildisplay = resendEmailliststyle?.display;

    let optionCancel = this.optionCancel.nativeElement;
    let optionCancelliststyle = window.getComputedStyle(optionCancel)
    let optionCanceldisplay = optionCancelliststyle?.display;

    if (optionCanceldisplay == 'none' && resendEmaildisplay == 'none' && triggerFrauddisplay == 'none' && processManualdisplay == 'none' && qrdisplay == 'none' && changeExpirydisplay == 'none') {
      this.showOption = false
    }
  }

  ngOnInit(): void {
    this.changeParcelStatuses = [{
        flow: 'LM',
        eventCode: 'NDE',
        eventSubCode: '12',
        description: this.translate.instant('Postman expired pick up')
      },
      {
        flow: 'LM',
        eventCode: 'BDU',
        eventSubCode: '1',
        description: this.translate.instant('Picked up by customer')
      },
      {
        flow: 'FM',
        eventCode: 'ACE',
        eventSubCode: '2',
        description: this.translate.instant('Picked up by postman')
      },
    ]
    $('.test').click(function () {
      $(this).find('i').toggleClass('fa fa-plus fa fa-minus');
    });
    $('.details').click(function () {
      $(this).find('i').toggleClass('fa fa-chevron-down fa fa-chevron-up');
    });
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.getParcelInformation(this.id)

    this.parcelForm = new FormGroup({
      comments: new FormControl('', [])
    })

    this.parcelDetailsForm = new FormGroup({
      dateOfExpirationFrom: new FormControl('', []),
      dateOfExpirationTo: new FormControl('', []),
    })

    this.expiryRequestForm = new FormGroup({
      expiryHours: new FormControl(''),
      pickupInstruction: new FormControl(''),
      comment: new FormControl('')
    })
    this.showPickupInstruction = false;

    setTimeout(() => {
      if (!this.result.reservation) return;

      let {
        from,
        to
      } = this.result.reservation

      if (!from || !to) return;

      this.parcelDetailsForm.patchValue({
        "dateOfExpirationFrom": from.substring(0, from.length - 8),
        "dateOfExpirationTo": to.substring(0, to.length - 8)
      })
    }, 3000)

    this.minimunExpirationDate = new Date().toISOString().slice(0, 16);
    this.currentParcelStatus = '';
  }

  changeParcelStatusSet(status: any) {
    this.changeParcelStatus = status
  }


  changeParcelStatusTrigger(status: any) {
    this.setLoading(true)
    this.ParcelService.changeStatus(this.id, status)
      .then(res => {
        this.activityCall(this.id)
        this.toastr.success(`Status changed to ${status.description}`, this.translate.instant('Success'))
      })
      .catch(err => {
        this.toastr.error(this.translate.instant('Error changing the parcel status'), this.translate.instant('Failed'));
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  getParcelInformation(reservationId) {
    let expiryDate = null;
    this.setLoading(true)
    this.ParcelService.getOne(reservationId)
      .subscribe(async res => {
        this.isFraud = false
        this.bdu1Status = false
        this.bde23Status = false
        this.ace2Status = false
        this.dru1Status = false
        this.nde12Status = false
        this.activityCall(reservationId)
        this.setLoading(false)
        this.type = true
        this.result = res;
        this.partnerName = this.result?.dds?.partner?.name
        //date formatting new
        if (this.result.date) {
          let dateval = moment(this.result.date).format("YYYY-MM-DD");
          this.result.date = dateval;
        }
        if (this.result.firstmileDate) {
          let dateval = this.result.firstmileDate.split('Z').join('');
          this.result.firstmileDate = dateval;
        }
        if (this.result.reservation && this.result.reservation.isReserved) {
          this.isReserved = true;
        }
        if (this.result.reservation && this.result.reservation.isReservedFirstMile) {
          this.isFirstMileReserved = true;
        }
        this.parcelType = this.result.deliveryPointOriginal.provider;
        if (this.parcelType == 'bpost') {
          this.isEligibleForProcessManually = false;
        }
        // if(this.result.expirationDate){
        //   expiryDate = this.datePipe.transform(this.result.expirationDate, 'yyyy-MM-dd HH:mm:ss');
        // }
        let fetchColumn = 'parcelPickUpCode,currentStatus,expiryDate,allPreviousStatus';
        let pickupCodeInfo: any = await this.ParcelService.getParcelInfo(this.result.barcode, this.result.id, fetchColumn)
        if (pickupCodeInfo?.parcelPickUpCode) {
          this.pickupCode = pickupCodeInfo.parcelPickUpCode
        }
        if (pickupCodeInfo?.expiryDate) {
          expiryDate = pickupCodeInfo.expiryDate;
        }
        if ([pickupCodeInfo?.currentStatus]) {
          this.currentParcelStatus = pickupCodeInfo.currentStatus;
        }
        if (this.result.deliveryPointOriginal.provider === 'Lean' || this.result.deliveryPointOriginal.provider == "") {
          this.type = false
        }
        this.ParcelService.getHistoricalData(this.result.barcode).subscribe((res: any) => {
          this.histDetails = res
          if (this.histDetails.historicalData.length > 0) {
            this.historicalDetails = this.histDetails.historicalData;
            let historicalData = this.histDetails.historicalData[0]
            this.computeEligibilityForExpiryUpdate();
            this.setExiprationdata(this.historicalDetails)
            this.checkBdeAndBduEligibility()
            this.computeProcessEligibility()
            this.checkValidChangeParcelStatusDropdwon()
          }
        });

        if (expiryDate) {
          let hDate = new Date(new Date(expiryDate).toDateString())
          this.highValue = hDate.getTime();
          this.result.expirationDate = this.getFormattedDate(new Date(this.highValue))
        } else {
          let dbDate = new Date(this.lowValue)
          let intialExipiration = dbDate.setDate(dbDate.getDate() + 5)
          let highValueNew = new Date(intialExipiration).getTime()
          this.highValue = highValueNew
          this.result.expirationDate = this.getFormattedDate(new Date(this.highValue))
        }

        if (this.result.parcelLockerRetrievalCode) {
          this.qrdata = this.result.parcelLockerRetrievalCode
        } else {
          // this.qrdata = "DUMMYQRCODE"
        }
        let currentFlowInfo: any = await this.ParcelService.getParcelInfo(this.result.barcode, this.result.id, "currentFlow")
        if (currentFlowInfo?.currentFlow) {
          this.parcelCurrentFlow = currentFlowInfo.currentFlow
        }

      }, (error) => this.toastr.error(this.translate.instant('failedToFetch'), this.translate.instant('Failed')))
  }
  activityCall(reservationId) {
    this.ParcelService.getActivityDetails(reservationId)
      .subscribe(async (res: any) => {
        let parcelStatus: any = await this.ParcelService.getParcelInfo(this.result.barcode, reservationId, 'deliveredBy,collectedBy,parcelPickUpCode')
        this.delivery = parcelStatus.deliveredBy
        this.collected = parcelStatus.collectedBy
        this.activity = res
        this.checkLockerEmpty();
        this.activity = this.activity.map(obj => {
          if (obj.eventCode === "BDE" && obj.eventSubCode === "23" && this.delivery) {
            return {
              ...obj,
              actor: this.delivery
            }
          } else if ((obj.eventCode === "ACE" && obj.eventSubCode === "2") || (obj.eventCode === "NDE" && obj.eventSubCode === "12")) {
            return {
              ...obj,
              actor: this.collected
            }
          }
          return obj
        })
        //replacement logic for strange code bwlow
        if (this.activity && this.activity.length) {
          for (let activObj of this.activity) {
            if (activObj.action == 'CANCEL') {
              this.iscancelled = true;
            }
            if (activObj.action == 'FRAUD') {
              this.isFraud = true;
            }
          }

        } else {
          this.iscancelled = false;
          this.isFraud = false;
        }

        for (let i = 0; i < this.activity.length; i++) {
          if (this.activity[i].eventDate) {
            //Assuming that date is in UTC, so converting to local time
            let utcEventDate = this.activity[i].eventDate.replace('Z', '');
            let localEventDate = moment.utc(utcEventDate).toDate();
            this.activity[i].eventDate = moment(localEventDate).format('YYYY-MM-DD HH:mm:ss');
          }
        }
      }, (error) => {
        this.toastr.error(this.translate.instant('failedToFetch'), this.translate.instant('Failed'));
      })

  }

  checkLockerEmpty = () => {
    //console.log('in check parcel');
    for (let activityObj of this.activity) {
      // console.log('ac obj');
      // console.log(activityObj);
      if (activityObj.action == "LOCKER_IS_EMPTY") {
        this.isLockerEmpty = true;
        // console.log('locker is empty')
      }
    }
  }

  copytoClipboardPickupCode() {
    try {
      const text = document.getElementById('copyPickupCode').innerText;
      //console.log(text, "text >>>")
      let f = navigator.userAgent.search("Firefox");
      if (f > -1) {
        alert("not compatable with firefox please try with any other broswer");
      } else {
        navigator.clipboard.writeText(text);
        this.copyBtn = "Copied";
      }
    } catch {
      alert("Sorry not able to copy Please retry or try download by right click from browser")
    }
  }

  // downloadImage() {
  //   const canvas = document.getElementsByTagName('canvas');
  //   var img = canvas[0].toDataURL("image/jpeg");
  //   this.href = img
  // }

  copy() {
    var copyText = document.getElementsByTagName('canvas');
    //copyText.select();
    document.execCommand("copy");
  }

  sendComments() {
    this.body = {
      text: this.parcelForm.value.comments
    }
    this.ParcelService.insertCommentsToDB(this.id, this.body).subscribe(
      res => {
        this.toastr.success(this.translate.instant('Comment Added'), this.translate.instant('Success'));
        this.ngOnInit();
      },
      err => {
        this.toastr.error(this.translate.instant('Adding Comment Failed'), this.translate.instant('Failed'));
        console.error(err);
      }
    )
  }

  cancelOrder() {
    let cancelOrderBody = {
      action: "CANCEL"
    }
    this.ParcelService.postRetrievalCode(this.id, cancelOrderBody).subscribe(
      res => {
        this.activityCall(this.id)
        this.toastr.success(this.translate.instant('Parcel Cancelled'), this.translate.instant('Success'));
      },
      err => {
        this.toastr.error(this.translate.instant('Cancelling The Order Failed'), this.translate.instant('Failed'));
        console.error(err);
      }
    )
  }

  flagFraud() {
    let flagFraudBody = {
      action: "FRAUD"
    }
    this.ParcelService.postRetrievalCode(this.id, flagFraudBody).subscribe(
      res => {
        this.fraudbutton = true
        this.activityCall(this.id);
        this.toastr.success(this.translate.instant('Parcel Flagged As Fraud'), this.translate.instant('Success'));
      },
      err => {
        this.fraudbutton = false
        this.toastr.error(this.translate.instant('Flagging As Fraud Failed'), this.translate.instant('Failed'));
        console.error(err);
      }
    )
  }

  processManually() {
    let processManuallyBody = {
      action: "CANCEL"
    }
    this.ParcelService.postRetrievalCode(this.id, processManuallyBody).subscribe(
      res => {
        this.activityCall(this.id)
        this.toastr.success(this.translate.instant('Process Manually Updated'), this.translate.instant('Success'));
      },
      err => {
        this.toastr.error(this.translate.instant('Processing Manually Failed'), this.translate.instant('Failed'));
        console.error(err);
      }
    )
  }

  updateExpirationDateNew = () => {
    this.setLoading(true)
    let timehours = moment()
    this.newDate = timehours.add(Number(this.expiryRequestForm.value.expiryHours), 'hours').format('YYYY-MM-DD HH:mm:ss')
    let updateExpirationRequest = {
      expirationBarcode: this.result.barcode,
      expiryHours: this.expiryRequestForm.value.expiryHours,
      pickupInstruction: this.expiryRequestForm.value.pickupInstruction,
      comment: this.expiryRequestForm.value.comment
    }
    this.ParcelService.postRetrievalCode(this.id, updateExpirationRequest).subscribe(
      (res: any) => {
        this.setLoading(false);
        this.setLoading(false);
        if (res.expiryDate) {
          let expDate = this.datePipe.transform(res.expiryDate, 'yyyy-MM-dd HH:mm:ss');
          this.result.expirationDate = expDate;
          this.activityCall(this.id)
          this.toastr.success(this.translate.instant('Expiration Date Updated'), this.translate.instant('Success'));
        } else {
          this.toastr.error(res.message, this.translate.instant('Failed'));
        }
      },
      err => {
        this.setLoading(false);
        this.toastr.error(this.translate.instant('Updating Expiration Date Failed'), this.translate.instant('Failed'));
        console.error(err);
        this.setLoading(false);
      }
    )

  }

  updateExpirationDate() {
    this.setLoading(true)
    let updateExpirationDateBody = {
      barcode: this.result.barcode,
      lmMaxAllowance: this.parcelDetailsForm.value.dateOfExpirationTo + ":00.000Z"
    }
    this.ParcelService.postRetrievalCode(this.id, updateExpirationDateBody).subscribe(
      (res: any) => {
        this.setLoading(false);
        if (res.expiryDate) {
          let expDate = this.datePipe.transform(res.expiryDate, 'yyyy-MM-dd HH:mm:ss');
          this.result.expirationDate = expDate;
          this.toastr.success(this.translate.instant('Expiration Date Updated to') + expDate, this.translate.instant('Success'));
        } else {
          this.toastr.error(res.message, this.translate.instant('Failed'));
        }
      },
      err => {
        this.toastr.error(this.translate.instant('Updating Expiration Date Failed'), this.translate.instant('Failed'));
        console.error(err);
        this.setLoading(false);
      }
    )
  }

  checkDisableExpirationUpdate = () => {
    let currentDateString = new Date().toISOString();
    let expirationDateString = this.result?.expirationDate;
    if (expirationDateString) {
      let expirationDate = new Date(expirationDateString.split("T")[0]);
      let currentDate = new Date(currentDateString.split("T")[0]);
      let diff = expirationDate.getTime() - currentDate.getTime();
      let daydiff = diff / (1000 * 60 * 60 * 24);
      if (daydiff >= 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  updateEmail(barcode) {
    //console.log(barcode)
    this.data = {
      purpose: "LASTMILE_DELIVERED"
    }
    this.ParcelService.sendEmail(this.data, barcode).subscribe(
      res => {
        this.toastr.success(this.translate.instant('Data Send Successfuly'), this.translate.instant('Success'));
      },
      err => {
        this.toastr.error(this.translate.instant('data sending failed !!'), this.translate.instant('Failed'));
        console.error(err);
      }
    )
  }

  onRadioSelect = (val) => {
    if (val == 0) {
      this.showPickupInstruction = true;
    } else {
      this.showPickupInstruction = false;
    }
  }
  shouldDisplayPickupInstruction = () => {
    return this.showPickupInstruction;
  }

  setExiprationdata(data) {
    for (let i = 0; i < data.length; i++) {
      let elem: any = this.historicalDetails[i];
      if (elem.eventCode == 'BDE' && elem.eventSubCode == "23") {
        let startDatenew = new Date(elem.eventTime).getTime()
        this.startDate = startDatenew
        this.customDateRange()
      }

    }
  }

  customDateRange() {
    let startvalue = new Date(this.startDate)
    let correctVal = startvalue.setDate(startvalue.getDate() - 1)
    this.lowValue = this.startDate
    const dates: Date[] = [];
    let startingDate = new Date(this.lowValue);
    startingDate = this.getFormattedDate(startingDate);
    dates.push(startingDate);
    for (let i: number = 1; i <= 30; i++) {
      var date = new Date(this.startDate);
      date = this.getFormattedDate(date);
      date.setDate(date.getDate() + i);
      dates.push(date);
    }
    this.dateRange = dates;
    this.sliderOptions.showSelectionBar = false

    this.sliderOptions.stepsArray = dates.map((date: Date) => {
      return {
        value: date.getTime()
      };
    })
    this.sliderOptions.translate = (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return new Date(value).toDateString();
        case LabelType.High:
          return new Date(value).toDateString();
        default:
          return new Date(value).toDateString();
      }
    }
  }

  sliderValChange = (changeContext: ChangeContext) => {
    let firstDateRangevalue = this.dateRange[0].toLocaleDateString()
    let firstLowValue = new Date(this.lowValue).toLocaleDateString()

    if (firstLowValue !== firstDateRangevalue) {
      this.toastr.error(this.translate.instant('cannot update start date !!'), this.translate.instant('Failed'));
      this.resetStartExp();
      return;
    }
    let updateToDate = new Date(this.highValue);
    // updateToDate.setHours(0,0,0,0);
    updateToDate = this.getFormattedDate(updateToDate);
    let expDate = this.datePipe.transform(updateToDate, 'yyyy-MM-dd HH:mm:ss');
    let isoval = expDate.toString();

    this.updateClassicExpiration(updateToDate);

  }
  resetStartExp = () => {
    setTimeout(() => {
      this.lowValue = this.dateRange[0].getTime();
    }, 500);
  }


  updateClassicExpiration = (isodate) => {
    //this.setLoading(true);

    let updateExpirationRequest = {
      expirationBarcodeClassic: this.result.barcode,
      expirationDate: this.datePipe.transform(isodate, 'yyyy-MM-dd HH:mm:ss'),
      deliverypointId: this.result.dds.deliverypoint?(this.result.dds.deliverypoint.id).toString() : null
    }
    // console.log(updateExpirationRequest);
    this.ParcelService.postRetrievalCode(this.id, updateExpirationRequest).subscribe(
      (res: any) => {
        this.setLoading(false);
        this.setLoading(false);
        if (res.expiryDate) {
          let expDate = this.datePipe.transform(res.expiryDate, 'yyyy-MM-dd HH:mm:ss');
          this.result.expirationDate = expDate;
          this.toastr.success(this.translate.instant('Expiration Date Updated'), this.translate.instant('Success'));
        } else {
          this.toastr.error(res.message, this.translate.instant('Failed'));
        }
      },
      err => {
        this.setLoading(false);
        this.toastr.error(this.translate.instant('Updating Expiration Date Failed'), this.translate.instant('Failed'));
        console.error(err);
        this.setLoading(false);
      }
    )
  }

  getFormattedDate(date: Date) {
    let d = date;
    if (d.getHours() < 12) {
      d.setHours(0, 0, 0, 0); // previous midnight day
    } else {
      d.setHours(0, 0, 0, 0); // next midnight day
    }
    // console.log('after:' + d);
    return d;
  }

  computeEligibilityForExpiryUpdate() {
    let hasBDE23 = this.historicalDetails.some((elem: any) => elem.eventCode == 'BDE' && elem.eventSubCode == "23");
    let hasNDE12 = this.historicalDetails.some((elem: any) => elem.eventCode == 'NDE' && elem.eventSubCode == "12");
    let hasACE2 = this.historicalDetails.some((elem: any) => elem.eventCode == 'ACE' && elem.eventSubCode == "2");
    let hasDRU1 = this.historicalDetails.some((elem: any) => elem.eventCode == 'DRU' && elem.eventSubCode == "1");
    let hasBDU1 = this.historicalDetails.some((elem: any) => elem.eventCode == 'BDU' && elem.eventSubCode == "1");

    this.isEligibleForExpiryUpdate = hasBDE23 && !hasNDE12 && !hasACE2 && !this.isFraud && !this.iscancelled;

    if (!this.isFraud && !this.iscancelled) {
      if (hasBDE23 && hasDRU1) {
        if (!hasBDU1 && !hasNDE12) {
          this.isEligibleForChangeCompartment = true;
        }
      } else {
        this.toolTipval = this.translate.instant('This action requires a delivered status')
        if (hasDRU1 && !hasACE2) {
          this.isEligibleForChangeCompartment = true;
        }
        if (hasBDE23 && !hasBDU1 && !hasNDE12) {
          this.isEligibleForChangeCompartment = true;
        }
      }
    }
  }

  onTextAreaInput(event: Event): void {
    const target = event.target as HTMLTextAreaElement;
    if (target.value.length > this.maxchar) {
      target.value = target.value.substring(0, 150)
    }
  }

  checkBdeAndBduEligibility() {
    let ind = this.historicalDetails.length
    let elem: any = this.historicalDetails[ind - 1];
    const {
      eventCode,
      eventSubCode
    } = elem;

    this.nde12Status = eventCode == 'NDE' && eventSubCode == "12";  // LM expired postman pick up
    this.bde23Status = eventCode == 'BDE' && eventSubCode == "23";  // LM delivered by postman
    this.bdu1Status = eventCode == 'BDU' && eventSubCode == "1";    // LM collected by customer

    this.ace2Status = eventCode == 'ACE' && eventSubCode == "2";    // FM pickedup by postman
    this.dru1Status = eventCode == 'DRU' && eventSubCode == "1";    // FM delivered by customer
  }

  checkValidChangeParcelStatusDropdwon() {
    let newValidChangeParcelStatusForClassic = []
    let chnageSatusOptions = this.changeParcelStatuses
    for (let index = 0; index < chnageSatusOptions.length; index++) {
      const element = chnageSatusOptions[index];

      const {
        eventCode,
        eventSubCode
      } = element;

      if (this.isFraud == true) {
        element.valClass = 'grey-class'
        element.toolTipval = this.translate.instant('Fraud barcode')
      } else {
        element.valClass = 'white-class'
        if (eventCode == "ACE" && eventSubCode == "2") { // FM pickedup by postman
          element.valClass = !this.isFirstMileReserved?'grey-class' : this.isFirstMileReserved && !this.dru1Status && !this.ace2Status?'grey-class' : 'white-class'
          element.toolTipval = !this.isFirstMileReserved?this.translate.instant('FM Reservation required') : !this.dru1Status && !this.ace2Status?this.translate.instant('This action requires a delivered status') : this.ace2Status?this.translate.instant('Already picked up by postman') : null
        }

        if (eventCode == "BDU" && eventSubCode == "1") {
          element.valClass = !this.isReserved?'grey-class' : this.isReserved && !this.bdu1Status?'grey-class' : 'white-class'
          element.toolTipval = !this.isReserved?this.translate.instant('LM Reservation required') : !this.bdu1Status?this.translate.instant('This action requires a delivered status') : this.bdu1Status?this.translate.instant('Already picked up by customer') : null
        }
        
        if (eventCode == "NDE" && eventSubCode == "12") {
          element.valClass = !this.isReserved || this.bdu1Status?'grey-class' : 'white-class'
          element.toolTipval = !this.isReserved?this.translate.instant('LM Reservation required') : this.bdu1Status?this.translate.instant('Already picked up by customer') : null
        }
      }
      newValidChangeParcelStatusForClassic.push(element)
    }
    this.changeParcelStatuses = newValidChangeParcelStatusForClassic
  }

  public getTooltipTextLM(): string {
    if (this.isFraud === true) {
      return this.translate.instant('Fraud barcode');
    } else {
      if (this.isReserved === true) {
        if (this.bde23Status === true) {
          return this.translate.instant('Already delivered by postman');
        }
        if (this.bdu1Status === true) {
          if (this.nde12Status === true) {
            return this.translate.instant('Postman pick up expired')
          } else {
            return this.translate.instant('Already picked up by customer');
          }
        }

        return null;
      } else {
        return this.translate.instant('LM Reservation required');
      }
    }
  }

  public getDropdownClsName(): string {

    if (this.isReserved === true) {
      if (this.bdu1Status === true || this.isFraud === true || this.nde12Status === true) {
        return 'grey-class';
      }
      if (this.bdu1Status === false && this.isFraud === false) {
        return 'white-class';
      }
      return 'white-class';
    } else {
      return 'grey-class';
    }
  }

  public getTooltipTextForFM(): string {
    if (this.isFraud === true) {
      return this.translate.instant('Fraud barcode');
    } else {
      if (this.isFirstMileReserved === true) {
        if (this.dru1Status === true) {
          return this.translate.instant('Already delivered by customer')
        }
        if (this.ace2Status === true) {
          if (this.nde12Status === true) {
            return this.translate.instant('Postman pick up expired')
          } else {
            return this.translate.instant('Already picked up by postman');
          }
        }
        return null;
      } else {
        return this.translate.instant('FM Reservation required');
      }
    }
  }

  public getDropdownClsNameForFM(): string {
    if (this.isFirstMileReserved === true) {
      if (this.ace2Status === true || this.isFraud === true || this.nde12Status === true) {
        return 'grey-class';
      }
      if (this.ace2Status === false && this.isFraud === false) {
        return 'white-class';
      }
      return 'white-class';
    } else {
      return 'grey-class';
    }
  }

  checkforInactive(value: any) {
    return value.includes("inactive")
  }
  computeProcessEligibility() {
    this.isEligible = this.historicalDetails.some(item => item.eventCode == 'BDE' && item.eventSubCode == '23')
  }

  timeFormatter(date) {
    const formattedDateTime = utcFormatter(date);
    if (!formattedDateTime) return null
    return moment(formattedDateTime).format('DD-MM-YYYY')
  }

  goToParcelList() {
    this.location.back();  }
}
