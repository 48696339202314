import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DeliverypointsService } from '../../../Service/deliverypoints.service';

@Component({
  selector: 'app-lockers-menu',
  templateUrl: './lockers-menu.component.html',
  styleUrls: ['./lockers-menu.component.scss']
})

export class LockersMenuComponent implements OnInit {
  @Input() id: any;
  lockerdetails: any;
  hardwareType:any

  constructor(public router: Router,
    private deliverypointsService: DeliverypointsService) { }

  ngOnInit(): void {
    this.fetchLockerDetails();
  }

  async fetchLockerDetails() {
    try {
      this.lockerdetails = await this.deliverypointsService.fetchwithId(this.id);
      this.hardwareType = this.lockerdetails?.hardwareType;
    } catch (error) {
      console.error('Error fetching locker details:', error);
    }
  }

  goToDetails() {
    this.router.navigate(['/deliverypoints/', this.id, 'details']);
  }

  goToLogs() {
    this.router.navigate(['/deliverypoints/', this.id, 'logs']);
  }

  goToTerminals() {
    this.router.navigate(['/deliverypoints/', this.id]);
  }

  filterConnectionLogs() {
    this.router.navigate(['/deliverypoints/', this.id, 'connectivity']);
  }

  goToSettings() {
    this.router.navigate(['/deliverypoints/', this.id, 'settings']);
  }
  

  goToCamera() {
    if (this.hardwareType === 'KEBA') {
      this.router.navigate(['/deliverypoints/' + this.id + '/camera']);
    } else {
      console.log('Camera not available for this hardware type');
    }
  }
}

