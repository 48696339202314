<app-header></app-header>
<app-lockers-menu *ngIf="lockerdetails" [id]="lockerdetails.id"></app-lockers-menu>
<div #myDiv [appViewController]="{deliveryPoints:'showDetails'}">
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div *ngIf="lockerdetails" id="navbarTogglerDemo01">
        <ul class="navbar-nav mr-2 mt-12 mt-lg-0">
          <li class="nav-item mr-2">
            <div *ngIf="lockerdetails.connectivity.online">
              <i class="fa fa-circle text-success" style="font-size:40px;"></i>
            </div>
            <div *ngIf="!lockerdetails.connectivity.online">
              <i class="fa fa-circle text-danger" style="font-size:40px;"></i>
            </div>
          </li>
          <li class="nav-item mr-2">
            <button [appViewController]="{deliveryPoints:'takeOver'}" type="button"
              class="btn btn-outline-danger" (click)='takeOver("main")'>{{'Take Over'|translate}}</button>
          </li>
          <li class="nav-item mr-2" *ngIf="lockerdetails.location!=undefined">
            <button class="btn btn-outline-blue btn-primary" (click)="openMap(lockerdetails.location.x,lockerdetails.location.y)">
              <i class="fas fa-map-marker-alt mr-1"></i>
              <span>{{'Go to Location'|translate}}</span>
            </button>
          </li>
          <li 
            class="nav-item mr-2"
            [appViewController]="{ deliveryPoints:'showOnboard'}"
            *ngIf="lockerdetails.features.canBeCommisioned"
          >
            <button
              type="button"
              class="btn btn-outline-danger"
              (click)="performCommissioning()"
            >Perform Commissioning</button>
        </li>

        <li *ngIf="commissioningCode"
          class="btn btn-link mr-2"
          (click)="copytoClipboardCommisioningCode()"
          >
          <i class="fas fa-regular fa-copy mr-2"></i>
          <span id="commisioningCode">{{commissioningCode}}</span>
        </li>
        </ul>
      </div>
      <div [appViewController]="{deliveryPoints:'restartButton'}" class="restartSec"
        *ngIf="lockerdetails && lockerdetails.type == 'Cubee Locker'">
        <button type="button" class="btn btn-danger mr-2 mb-2" (click)="restart()">
          <i class="fa fa-refresh" aria-hidden="true"></i>
          <span style="margin-left: 10px;">{{'Restart'|translate}}</span></button>
      </div>
    </nav>
  </div>
  <br>
  <form [formGroup]="lockerDetailsForm" (ngSubmit)="onSubmit()">
    <div class="form-row" style="margin-right: 0px !important;">
      <div class="form-group col-md-6">
        <div *ngIf="lockerdetails" class="pl-2 pr-2">
          <div class="pl-4 pr-4">
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-4 col-form-label">{{'Delivery Point ID'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="id" id="id" placeholder=" {{'Id'|translate}}"
                  readonly>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Locker Name'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" id="lockerName" formControlName="lockerName"
                  placeholder="{{'Locker Name'|translate}}" readonly>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'IP Address'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" id="Ip Address" formControlName="ipAddress"
                  placeholder="{{'ipAddress'|translate}}" readonly>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Last Ping Time'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" id="Updated At" formControlName="updatedAt"
                  placeholder="{{'updatedAt'|translate}}" readonly>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Locker ID'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="lockerId" id="lockerId"
                  placeholder="{{'Locker ID'|translate}}" readonly>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'AC Code'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="acCode" id="acCode"
                  placeholder="{{'AC Code'|translate}}" readonly>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Operational Status'|translate}}</label>
              <div class="col-sm-6">
                <select class="custom-select mr-sm-2" formControlName="lockerOperationActive"
                  id="lockerOperationActive">
                  <option value="true">{{'Yes'|translate}}</option>
                  <option value="false">{{'No'|translate}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Locker Provider'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="provider" id="provider"
                 readonly>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Hardware Provider'|translate}}</label>
              <div class="col-sm-6">
                <select class="custom-select mr-sm-2" formControlName="hardwareType" name="hardwareType">
                  <option value="" selected>{{'Choose Option'|translate}}</option>
                  <option value="ByBox">{{'ByBox'|translate}}</option>
                  <option value="Swipbox">{{'Swipbox'|translate}}</option>
                  <option value="DBI">{{'DBI'|translate}}</option>
                  <option value="KEBA">{{'KEBA'|translate}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Locker Type'|translate}}</label>
              <div class="col-sm-6">
                <select class="custom-select mr-sm-2" formControlName="type" name="type" disabled>
                  <option selected>{{'Choose Type...'|translate}}</option>
                  <option value="Lean">{{'Lean Locker'|translate}}</option>
                  <option value="Classic">{{'Classic Locker'|translate}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row" *ngIf="isClassicLocker">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Locker IP Address'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" id="acCode" placeholder="Locker IP" [value]="lockerIP" readonly>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Technology'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="technology" id="technology" placeholder="null"
                  readonly>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Commissioned'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="commissioned" id="commissioned"
                  placeholder="{{'Commissioned'|translate}}" readonly>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Street'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="street" id="street" placeholder="Street"
                  readonly>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Street Number'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="streetNumber" id="streetNumber"
                  placeholder="Street Number" readonly>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Postal Code'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="postalCode" id="postalCode"
                  placeholder="Postal Code" readonly>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Municipality'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="municipality" id="municipality"
                  placeholder="Municipality" readonly>
              </div>
            </div>
            <div class="form-group row"
              *ngIf="lockerType == 'Cubee Locker' && (lockerProvider === 'Cubee' || lockerProvider === 'bpost')">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Online'|translate}}</label>
              <div class="col-sm-6">
                <select class="custom-select mr-sm-2" formControlName="active" id="active" disabled>
                  <option value="true">{{'True'|translate}}</option>
                  <option value="false">{{'False'|translate}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Soft Reservation'|translate}}</label>
              <div class="col-sm-6">
                <select class="custom-select mr-sm-2" formControlName="acceptSoftReservations"
                  id="acceptSoftReservations">
                  <option value="" selected>{{'Choose Option'|translate}}</option>
                  <option value="true">{{'True'|translate}}</option>
                  <option value="false">{{'False'|translate}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Wind Brackets'|translate}}</label>
              <div class="col-sm-6">
                <select class="custom-select mr-sm-2" formControlName="acceptWindBrackets" id="acceptWindBrackets">
                  <option value="" selected>{{'Choose Option'|translate}}</option>
                  <option value="Yes">{{'Yes'|translate}}</option>
                  <option value="No">{{'No'|translate}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Position'|translate}}</label>
              <div class="col-sm-6">
                <select class="custom-select mr-sm-2" formControlName="acceptPosition" id="acceptPosition">
                  <option value="" selected>{{'Choose Option'|translate}}</option>
                  <option value="Indoor">{{'Indoor'|translate}}</option>
                  <option value="Outdoor">{{'Outdoor'|translate}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Sales Segment'|translate}}</label>
              <div class="col-sm-6">
                <select class="custom-select mr-sm-2" formControlName="acceptSalesSegment" id="acceptSalesSegment">
                  <option value="" selected>{{'Choose Option'|translate}}</option>
                  <option value="Cities and Communes">{{'Cities and Communes'|translate}}</option>
                  <option value="Headquarter">{{'Headquarter'|translate}}</option>
                  <option value="Hospital">{{'Hospital'|translate}}</option>
                  <option value="Immo residential">{{'Immo residential'|translate}}</option>
                  <option value="Leisure Centre">{{'Leisure Centre'|translate}}</option>
                  <option value="Petrol Station">{{'Petrol Station'|translate}}</option>
                  <option value="Post Office">{{'Post Office'|translate}}</option>
                  <option value="Retail">{{'Retail'|translate}}</option>
                  <option value="Retailpark">{{'Retailpark'|translate}}</option>
                  <option value="SME">{{'SME'|translate}}</option>
                  <option value="Train Station">{{'Train Station'|translate}}</option>
                  <option value="University">{{'University'|translate}}</option>
                  <option value="Other transport">{{'Other transport'|translate}}</option>
                  <option value="Other">{{'Other'|translate}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Contract Name'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="acceptContractName" id="acceptContractName"
                  placeholder="{{'Contract Name'|translate}}">
                <div
                  *ngIf="lockerDetailsForm.controls['acceptContractName'].invalid && (lockerDetailsForm.controls['acceptContractName'].dirty || lockerDetailsForm.controls['acceptContractName'].touched)"
                  class="alert">
                  <div class="errormsg" *ngIf="lockerDetailsForm.controls['acceptContractName'].errors.pattern">
                    {{'Please enter valid value.'|translate}}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Contract Hostname'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="acceptContractHostname"
                  id="acceptContractHostname" placeholder="{{'Contract Hostname'|translate}}">
                <div
                  *ngIf="lockerDetailsForm.controls['acceptContractHostname'].invalid && (lockerDetailsForm.controls['acceptContractHostname'].dirty || lockerDetailsForm.controls['acceptContractHostname'].touched)"
                  class="alert">
                  <div class="errormsg" *ngIf="lockerDetailsForm.controls['acceptContractHostname'].errors.pattern">
                    {{' Please enter valid value.'|translate}}
                  </div>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Total Doors'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="acceptTotalDoors" id="acceptTotalDoors"
                  placeholder="{{'Total no. of doors'|translate}}" readonly>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Number of Units'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="acceptNumberOfUnits" id="acceptNumberOfUnits"
                  appNumbersOnly placeholder="{{'Number of Units'|translate}}">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Sales Key Account'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="acceptSalesKeyAccount"
                  id="acceptSalesKeyAccount" placeholder="{{'Sales Key Account'|translate}}">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Operations Field
                Support'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="acceptOperationsFieldSupport"
                  id="acceptOperationsFieldSupport" placeholder="{{'Operations Field Support'|translate}}">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label" position="center">{{'Location Delivery
                Route'|translate}}</label>
              <div class="col-sm-6">
                <textarea type="text" cols="20" rows="5" class="form-control"
                  formControlName="acceptLocationDeliveryRoute" id="acceptLocationDeliveryRoute"
                  placeholder="{{'Location Delivery Route'|translate}}"></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Mail Office'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="acceptMailOffice" id="acceptMailOffice"
                  placeholder="{{'Mail Office'|translate}}">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Logistics Platform'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="acceptLogisticsPlatform"
                  id="acceptLogisticsPlatform" placeholder="{{'Logistics Platform'|translate}}">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Mail Centre'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="acceptMailCentre" id="acceptMailCentre"
                  placeholder="{{'Mail Centre'|translate}}">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Wind Direction'|translate}}</label>
              <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="acceptWindDirection" id="acceptWindDirection"
                  placeholder="{{'Wind Direction'|translate}}">
              </div>
            </div>
            <div
              [appViewController]="{ deliveryPoints:'enableCamera'}"
              *ngIf="lockerdetails.features.camera"
              class="form-group row"
            >
              <label for="inputPassword3" class="col-sm-4 col-form-label">{{'Camera Enabled'|translate}}</label>
              <div class="col-sm-6">
                <select class="custom-select mr-sm-2" formControlName="cameraEnabled"
                  id="cameraEnabled">
                  <option value="true">{{'True'|translate}}</option>
                  <option value="false">{{'False'|translate}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div *ngIf="lockerdetails" class="form-group col-md-12">

          <div class="pl-4 pr-4">
            <div class="form-group row" *ngIf="lockerRegularHours">

              <div class="table-responsive pl-4 pr-4">
                <div style="text-align:center;font-weight: bold; font-size: large;">{{'Regular Hours'|translate}}</div>
                <table class="table table-sm table-bordered table-hover pl-4 pr-4" style="text-align:center;">
                  <caption></caption>
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col" class="fontedit">{{'DAY'|translate}}</th>
                      <th scope="col" class="fontedit">{{'FROM'|translate}}</th>
                      <th scope="col" class="fontedit">{{'TO'|translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="table" *ngFor="let item of lockerRegularHours">
                      <td>{{item.openDay}}</td>
                      <td>{{item.openTime}}</td>
                      <td>{{item.closeTime}}</td>
                    </tr>
                  </tbody>
                </table>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="lockerdetails" class="col-md-auto text-center" style="margin-bottom: 10px;">
      <button [appViewController]="{deliveryPoints:'updateLockerDetails'}" type="submit"
        class="btn btn-danger mr-md-auto" style="margin-bottom: 10px;">{{'Update'|translate}}</button>
    </div>
  </form>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>
