<app-header></app-header>
<div [appViewController]="{deliveryPoints:'showList'}" #myDiv>
  <div class="bg-info">

    <nav class="navbar navbar-expand-lg navbar-dark">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <i class="details btn center mx-auto" type="button" data-toggle="collapse" data-target="#collapseDashBoard"
          aria-expanded="false" aria-controls="collapseDashBoard">
          <i class="fa fa-chevron-down fa-lg text-white"></i>
        </i>
      </div>
    </nav>
  </div>
  <div class="box collapse" id="collapseDashBoard">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card bg-white mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col-5">
                  <div class="row">
                    <div class="col">
                      <h5 class="font">{{'Commisioned'|translate}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="font-weight-bold textSize bg-dark text-center text-light">{{commissioned}}
                        / {{notCommissioned}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="row">
                    <div class="col">
                      <h5 class="font">{{'Online'|translate}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="font-weight-bold textSize text-success bg-dark text-center">{{online}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="row">
                    <div class="col">
                      <h5 class="font">{{'Offline'|translate}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="font-weight-bold textSize text-danger bg-dark text-center">{{offline}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="container-fluid">
    <div>
      <nav class="navbar navbar-expand-lg navbar-dark pr-1">
        <ul class="navbar-nav mr-0 ml-auto mt-0 mt-lg-0">
          <!-- <li class="nav-item">
            <input type="image" src="/assets/img/redIcon.png" name="submit" width="28" height="28" alt="submit"
              (click)="exportToCSV()" [appViewController]="{deliveryPoints:'exportList'}">
          </li> -->
          <li class="nav-item" *ngIf="!showDownload">
            <!-- <input type="image" src="/assets/img/redIcon.png" name="submit" width="28" height="28" alt="submit"
              (click)="exportToCSV()" [appViewController]="{deliveryPoints:'exportList'}"> -->
              <div style="margin-right:20px; margin-top:10px;height: 20px; margin-bottom: 10px" > </div>

          </li> 
          <li class="nav-item" *ngIf="showDownload">
            <input type="image" src="/assets/img/redIcon.png" name="submit" width="28" height="28" alt="submit"
              (click)="exportToCSV()" [appViewController]="{deliveryPoints:'exportList'}">
          </li>
        </ul>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <form [formGroup]="filterForm" (ngSubmit)="onSubmit()" class="">
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="lockername">{{'Locker Name' | translate}}</label>
              <input class="form-control" formControlName="lockername" name="lockername"
                placeholder="{{'Locker Name'|translate}}">
            </div>
            <div class="form-group col-md-4">
              <label for="address">{{'Street'|translate}}</label>
              <input class="form-control" formControlName="street" name="street" placeholder="{{'Address'|translate}}">
            </div>
            <!-- <div class="form-group col-md-3">
              <label for="acCode">{{'Postal Code'|translate}}</label>
              <input type="text" class="form-control" formControlName="postalCode" name="Postal Code" appNumbersOnly
                placeholder="{{'Postal Code'|translate}}">
            </div> -->
            <div class="form-group col-md-4">
              <label for="lockername">{{'AC Code'|translate}}</label>
              <ng-multiselect-dropdown class="form-control-sm" formControlName="acCode"
                [placeholder]="'-All-'|translate" [settings]="acCodedropdownSettings" [data]="acCodedropdownList"
                [(ngModel)]="acCodeselectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
              </ng-multiselect-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="lockername">{{'Locker ID'|translate}}</label>
              <input class="form-control" formControlName="lockerid" name="lockerid"
                placeholder="{{'Locker ID'|translate}}">
            </div>
            <div class="form-group col-md-4">
              <label for="acCode">{{'Hardware Provider'|translate}}</label>
              <select class="custom-select mr-sm-2" formControlName="hardwareProvider" name="hardwareProvider">
                <option selected value="">{{'Choose Provider...'|translate}}</option>
                <option value="ByBox">{{'ByBox'|translate}}</option>
                <option value="Swipbox">{{'Swipbox'|translate}}</option>
                <option value="DBI">{{'DBI'|translate}}</option>
                <option value="KEBA">{{'KEBA'|translate}}</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="formGroupExampleInput2">{{'Locker Type'|translate}}</label>
              <select class="custom-select mr-sm-2" formControlName="lockertype" name="lockertype">
                <option selected value="">{{'Choose Type...'|translate}}</option>
                <option value="Lean">{{'Lean Locker'|translate}}</option>
                <option value="Cubee">{{'Cubee Locker'|translate}}</option>
              </select>
            </div>
          </div>
          <div class="collapse" id="collapseAdvancedFilter">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="online">{{'Online'|translate}}</label>
                <select class="custom-select mr-sm-2" formControlName="online" name="online">
                  <option selected value="">{{'Choose Option...'|translate}}</option>
                  <option value="true">{{'Yes'|translate}}</option>
                  <option value="false">{{'No'|translate}}</option>
                </select>
              </div>
                <div class="form-group col-md-4">
                  <label for="">{{'Active'|translate}}</label>
                  <select class="custom-select mr-sm-2" formControlName="active" name="active">
                    <option selected value="">{{'Choose Option...'|translate}}</option>
                    <option value="true">{{'Active'|translate}}</option>
                    <option value="false">{{'Inactive'|translate}}</option>
                  </select>
                </div>
              <!-- <div class="form-group col-md-4">
                <label for="commisioned">{{'Commisioned'|translate}}</label>
                <select class="custom-select mr-sm-2" formControlName="commisioned" name="commisioned">
                  <option selected value="">{{'Choose Option...'|translate}}</option>
                  <option value="true">{{'Yes'|translate}}</option>
                  <option value="false">{{'No'|translate}}</option>
                </select>
              </div> -->
              <div class="form-group col-md-4">
                <label for="technology">{{'Technology'|translate}}</label>
                <select class="custom-select mr-sm-2" formControlName="technology" name="technology">
                  <option selected value="">{{'Choose Option...'|translate}}</option>
                  <option value="awsiot">{{'aws-iot'|translate}}</option>
                  <option value="others">{{'others'|translate}}</option>
                </select>
              </div>
              <div class="form-group col-md-4" *ngIf="partnerDropdown">
                <label for="partnerId">{{'Partner'|translate}}</label>
                <ng-multiselect-dropdown class="form-control-sm placeholderText" formControlName="partnerId"
                  [placeholder]="'Choose Partner...'|translate" [settings]="partnerNamedropdownSettings"
                  [data]="partnerNamedropdownList" [(ngModel)]="partnerNameselectedItems"
                  (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
              </div>
              <div class="form-group col-md-4">
                <label style="margin: 6px;" for="Location Holiday"> {{'Holiday Date'|translate}}</label>
                <!-- <input class="form-control" formControlName="holidayDate" type="date" name="holidayDate"
                  max="9999-12-31" placeholder="{{'DeliveryDate'|translate}}"> -->
                  <div class="container">
                    <div class="row">
                      <div class="input-group">
                        <input
                          style="height: 37px;"
                          type="text"
                          placeholder="dd-mm-yyyy"
                          class="form-control"
                          formControlName="holidayDate"
                          #holidayDateInput
                          bsDatepicker
                          [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                          <!-- bsDaterangepicker -->
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <i class="fas fa-calendar-alt" (click)="openDatePicker('holidayDateInput')"></i>
                            </span>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="form-group col-md-4">
                <label style="margin: 5px;" for="online">{{'Soft Reservation'|translate}}</label>
                <select class="custom-select mr-sm-2" formControlName="acceptSoftReservations"
                  name="acceptSoftReservations">
                  <option selected value="">{{'Choose Option...'|translate}}</option>
                  <option value="true">{{'Yes'|translate}}</option>
                  <option value="false">{{'No'|translate}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <button class="btn btn-danger mr-3 mt-2" type="submit">{{'Filter'|translate}}</button>
                <button class="btn btn-danger mr-3 mt-2" type="button" data-toggle="collapse"
                  data-target="#collapseAdvancedFilter" aria-expanded="false" aria-controls="collapseAdvancedFilter">
                  {{ 'Advanced Filters'|translate}}
                </button>
                <button class="btn btn-outline-danger mt-2" type="submit"
                  (click)="onReset()">{{'Reset'|translate}}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>


  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-sm table-bordered table-hover row-border hover" style="text-align:left;">
            <caption></caption>
            <thead class="thead-dark">
              <tr>
                <th scope="col">{{'AC Code'|translate}}</th>
                <th scope="col">{{'Locker ID'|translate}}</th>
                <th scope="col">{{'Name'|translate}}</th>
                <th scope="col">{{'Location'|translate}}</th>
                <th scope="col">{{'Locker Type'|translate}}</th>
                <!-- <th scope="col">{{'Online'|translate}}</th> -->
                <th scope="col">{{'Last Ping Time'|translate}}</th>
                <!-- <th scope="col">Soft Reservation</th> -->
                <th scope="col">{{'Operational Status'|translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr  *ngFor="let res of result"
                [ngClass]="{'table-danger':(!res.connectivity.online || isAlert)&& res.provider !=null && res.provider.toLowerCase() != 'lean', 'table-success': (res.connectivity.online && !isAlert) && res.provider !=null && res.provider.toLowerCase() != 'lean', '': res.provider !=null && res.provider.toLowerCase() == 'lean'}">
                <td *ngIf="res.acCode">{{res.acCode}}</td>
                <td *ngIf="!res.acCode">-</td>
                <td>{{res.lockerId}}</td>
                <td *ngIf="res.lockerName">
                  <a [routerLink]="['/deliverypoints/', res.id]" target="_blank"
                    rel="noreferrer noopener">{{res.lockerName}}</a></td>
                <td *ngIf="!res.lockerName">
                  <a [routerLink]="['/deliverypoints/', res.id]" target="_blank"
                    rel="noreferrer noopener">Unnamed</a></td>

                <td *ngIf="!res.address.street || !res.address.streetNumber || !res.address.postalCode">-</td>
                <td *ngIf="res.address.street || res.address.streetNumber || res.address.postalCode">
                  {{res.address.street}},{{res.address.streetNumber}},{{res.address.postalCode}},{{res.address.municipality}}
                </td>

                <td *ngIf="!res.type">-</td>
                <td *ngIf="res.type">{{res.type}}</td>

                <td>{{res.connectivity.updatedAt | date:'dd/MM/YYYY HH:mm:ss'}}</td>

                <!-- <td *ngIf="res.type == 'Cubee Locker' && (!res.connectivity.online || isAlert)">False</td>
                <td *ngIf="res.type == 'Cubee Locker' && (res.connectivity.online && !isAlert)">True</td>
                <td *ngIf="res.type == 'Lean Locker'">-</td> -->

                <!-- <td *ngIf="!res.operations.acceptSoftReservations">False</td>
              <td *ngIf="res.operations.acceptSoftReservations">True</td> -->

                <td *ngIf="!res.operations.lockerOperationActive">false</td>
                <td *ngIf="res.operations.lockerOperationActive">{{res.operations.lockerOperationActive}}</td>
              </tr>
            </tbody>

          </table>
          <br>
        </div>

        <!-- <div class="table-responsive" *ngIf="!result?.length">
        <table class="table table-sm table-bordered table-hover row-border hover" style="text-align:center;">
          <tbody>
            <tr>
              <td style="text-align: center;">
                No Records Found!
              </td>
            </tr>
          </tbody>
        </table>
        <br>
      </div> -->

      </div>
    </div>
  </div>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>
