import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../../Service/partner.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-partner-edit-times',
  templateUrl: './partner-edit-times.component.html',
  styleUrls: ['./partner-edit-times.component.scss']
})
export class PartnerEditTimesComponent implements OnInit {

  showSpinner: boolean = false;
  partnerDetails: any;
  partnerId: any

  settingForm: FormGroup;

  settingName: string = 'postmans-codes-method'

  constructor(public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private partnerService: PartnerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private spinnerService: SpinnerService
  ) {
    this.settingForm = this.formBuilder.group({
      dwell: [0, Validators.required],
      FM_RESERVATION_LIMIT: [0],
      LM_RESERVATION_LIMIT: [0]
    })
  }

  ngOnInit(): void {
    let partnerId = this.activatedRoute.snapshot.params.id;
    this.partnerId = partnerId
    this.getPartnerDetails(partnerId);
  }

  //Fech partner details
  getPartnerDetails(partnerId) {
    this.showSpinner = true;
    this.partnerService.fetchPartnerDetails(partnerId).subscribe(
      res => {
        this.partnerDetails = res

        // Current setting value
        const dwell = this.partnerDetails.settings.find(s => s.key === 'DWELL_TIME');
        const fmRes = this.partnerDetails.settings.find(s => s.key === 'FM_RESERVATION_LIMIT');
        const lmRes = this.partnerDetails.settings.find(s => s.key === 'LM_RESERVATION_LIMIT');
        this.settingForm.patchValue({
          dwell: dwell?.value || 0,
          FM_RESERVATION_LIMIT: fmRes?.value,
          LM_RESERVATION_LIMIT: lmRes?.value
        })

        this.showSpinner = false;
      },
      err => {
        this.showSpinner = false;
        this.toastr.error(this.translate.instant("Failed to fetch partner details !!"),this.translate.instant("Failed"));
        console.error(err);
      }
    )
  }

  onSubmit() {
    if (this.settingForm.valid) {
      this.spinnerService.show();
      this.partnerService.editPartnerSettings(this.partnerId, {
        DWELL_TIME: this.settingForm.controls.dwell.value,
        FM_RESERVATION_LIMIT: this.settingForm.controls.FM_RESERVATION_LIMIT.value,
        LM_RESERVATION_LIMIT: this.settingForm.controls.LM_RESERVATION_LIMIT.value
      }).subscribe((res) => {
        this.spinnerService.hide();
        if (res) {
          this.toastr.success(this.translate.instant("Partner Updated Successfully!"), this.translate.instant("Success"))
        }
      }, (err: any) => {
        this.spinnerService.hide();
        console.error(err);
        this.toastr.info(err.error.error.message, 'Failed')
      })
    }
  }

  goToPartnerDetails() {
    this.router.navigate(['/partners/', this.partnerId]);
  }
}
