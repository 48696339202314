import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { ignoreElements } from 'rxjs/operators';
import { AppCookieService } from 'src/app/Service/app.cookie.service';
import { UserService } from '../../../Service/user.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
@Component({
  selector: 'app-usergroup',
  templateUrl: './usergroup.component.html',
  styleUrls: ['./usergroup.component.scss']
})
export class UsergroupComponent implements OnInit {

  groupData: any;
  userGrop: any;
  titleKeys:any;
  deleteID:any;
  showUnauthorized:boolean=false;
  showLoader: boolean = false;
  @ViewChild('myDiv',{static:true}) myDiv : ElementRef;
  constructor(
    public router: Router,
    private cookie: AppCookieService,
    private user: UserService,
    private spinner: SpinnerService
  ) { }


  ngOnInit(): void {
    this.spinner.show();
    this.user.getUsergroup().subscribe((res: any) => {
      let data = res;
      this.groupData = data.reverse()
      this.setUserGroup()
    },
      err => {
        this.spinner.hide();
        console.error(err);
      })
  }

  ngAfterViewInit()
  {
    let divElement = this.myDiv.nativeElement;
    let divstyle= window.getComputedStyle(divElement)
    let display= divstyle?.display;
    if(display=='none')
    {
      this.showUnauthorized=true
    }
  }

  goTogroupadd() {
    this.router.navigate(['/user/usergroupaddedit/' + 'add'])
  }

  goTogroupedit(item)
 {
  this.router.navigate(['/user/usergroupaddedit/' + 'edit' +'/' + item])
 }
  setUserGroup()
  {
    // Get default permissions from the user checklist data
    const defaultPermissions = this.user.checklistData;

    // Get the user groups from the API
    this.userGrop = this.groupData.map((element)=>element.userGroups)

    // Loop through the user groups and add the default permissions
    this.userGrop.forEach((element)=>{
      // Extend the user group with the default permissions
      element.checklistData = element.checklistData.map((item)=>{
        const key = Object.keys(item)[0];
        const defaultList = defaultPermissions.find((obj)=>Object.keys(obj)[0]===key);
        // Merge the default permissions with the user group permissions
        item[key] = Object.assign({}, defaultList[key], item[key]);
        return item;
      });
    })

    this.setTitleKeys()
    this.spinner.hide();
  }

  setTitleKeys()
  {
    let titleset = this.user.checklistData;
    this.titleKeys=titleset.map((obj)=>{
      for(let i in obj)
      {
        return i
      }
    })
  }

  savedelete(item:any)
  {
  let deleteobj=this.groupData.find((obj)=>{
    
    if(obj.userGroups!=null)
    {
     
      if(obj.userGroups.title==item)
      {
        return obj
      }
    }
  })
  this.deleteID=deleteobj.id;
  }
  deleteUser()
  {
    this.user.deleteUsergroup(this.deleteID).subscribe(res=>{
      this.ngOnInit();
    },err=>{
      console.error(err)
    })
  }

  getObjectKeys(item:any,index:any):any[]
  {
    return Object.keys(item[this.titleKeys[index]])
  }

}
