<app-header></app-header>
<app-lockers-menu [id]="id" ></app-lockers-menu>
<div class="container">
  <form [formGroup]="cameraOperationForm" class="container mat-card mb-6 mt-4 shadow bg-white rounded">
    <br>
    <p class="h4" style="text-align: center; margin-top: 10px; margin-bottom: 18px;">{{'Download Camera Images'|translate}}</p>
    <div class="form-row">
      <div class="form-group col-md-3 date-picker" style="margin-left: 12%;">
        <label for="dateFrom">{{'Date From' | translate}}</label>
        <div class="card">         
          <div>         
              <div class="container">
                <div class="row">
                  <div class="input-group">
                    <input
                      style="height: 35px;"
                      type="text"
                      placeholder="dd-mm-yyyy"
                      class="form-control"
                      formControlName="dateFrom"
                      [maxDate]="maxDate"
                      #dateFromInput
                      bsDatepicker
                      [bsConfig]="{ isAnimated: true, showWeekNumbers: false, dateInputFormat: 'DD-MM-YYYY' }">
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fas fa-calendar-alt" (click)="openDatePicker(dateFromInput)"></i>
                        </span>
                      </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col text-center mb-3 mt-3">
            <button class="btn btn-outline-danger mb-3 mr-3" type="submit" (click)="onDownload()">{{'Download'| translate}}</button>
            <button class="btn btn-outline-danger mb-3" (click)="onReset()">{{'Reset'| translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
