import { Component, OnInit ,ElementRef,Renderer2,ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { DeliverypointsService } from 'src/app/Service/deliverypoints.service';
import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../Service/app.service';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SpinnerService } from 'src/app/Service/spinner.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

  @Component({
  selector: 'app-camera',
  templateUrl: './cameraoperations.component.html',
  styleUrls: ['./cameraoperations.component.scss']
})
export class CameraComponent implements OnInit {
  id: string;
  showSpinner: boolean = true
  public cameraOperationForm
  result: any;
  list: any[];
  Camera: any;
  noFound: string;
  selectFields: string;
  translateData: any;
  @ViewChild('dateFromInput') dateFromInput: ElementRef;
  maxDate = new Date()
  constructor(
    private langtransService: LangtransService,
    public activatedRoute: ActivatedRoute,
    private deliverypointsService: DeliverypointsService,
    private appService: AppService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private sanitizer : DomSanitizer,
    private spinner : SpinnerService

  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data
    })
    this.cameraOperationForm = new FormGroup({
      dateFrom: new FormControl('', []),
      download: new FormControl('', []),
      reset: new FormControl('',[])
    })



}

  onReset() {
  this.cameraOperationForm.reset()
  }
  onDownload() {
    this.spinner.show()
    var obj = {
       dateFrom: this.datePipe.transform(this.cameraOperationForm.controls.dateFrom.value, 'yyyy-MM-dd')
    }

    if (!obj.dateFrom) {
      this.selectFields = 'selectFieldCameraOperations'

      this.langaugeFetching(this.translateData, this.selectFields)
      this.spinner.hide()
    }
    else {

       this.deliverypointsService.onDownloadApiCall(this.id, obj).subscribe((res)=>{
        this.selectFields = 'CameraImagesDownloadedSuccessfull'
        this.langaugeFetching(this.translateData, this.selectFields)

        this.deliverypointsService.onurlApiCall(this.id,obj).subscribe((res:any)=>
          {
            try{
              let link = document.createElement("a")
              link.href = res
              link.style.visibility = 'none'
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
              this.toastr.success(this.translate.instant('CameraImagesDownloadedSuccessfull'), this.translate.instant('Success'));
              this.spinner.hide()
            }
            catch{
              this.toastr.error(this.translate.instant('Errordownloadingthefile'), this.translate.instant('Error'))
              this.spinner.hide()
            }
          },
          (error)=>{
            if(error.error.message == "No images were found"){
              this.toastr.warning(this.translate.instant('No images were found'))
              this.spinner.hide()
            }
            else{
            console.error(error)
            this.toastr.error(this.translate.instant('Errordownloadingthefile'), this.translate.instant('Error'))
            this.spinner.hide()
            }
          })

       },(error)=>{
          console.error(error)
          this.toastr.error(this.translate.instant('Errordownloadingthefile'), this.translate.instant('Error'))
          this.spinner.hide()
       });




    }
  }

 /* async onDownloadApiCall(obj) {
   const newArray = []
   this.id = this.activatedRoute.snapshot.paramMap.get('id')

   this.deliverypointsService.fetchwithId(this.id)
  } */



   openDatePicker(v) {
    if (v == 'dateFromInput')
      this.renderer.selectRootElement(this.dateFromInput.nativeElement).click()
  }
  langaugeFetching(lang, key) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (this.noFound == 'NorecordsFound') {
          this.toastr.warning(res)
        }
        if (this.selectFields == 'selectFieldCameraOperations') {
          this.toastr.error(res)
        }
      }
    )
  }
}
