import { Directive, Input, Renderer2, ElementRef } from '@angular/core';
import { UserService } from '../../src/app/Service/user.service'

@Directive({
  selector: '[appViewController]'
})
export class ViewControllerDirective {
  userInfo: any;
  userGroup: any;
  currentUserRoles: any
  apiCalled: boolean = false
  @Input() appViewController: any;

  constructor(
    private render: Renderer2,
    private el: ElementRef,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.findUserRoles();
  }

  findUserRoles() {
    let visibilty = false;

    const permissionCheck = this.appViewController

    // Check if the feature exists in the permissions object
    const featureName = Object.keys(permissionCheck)[0]
    const operationName = permissionCheck[featureName]

    if (this.userService.checkPermission(featureName, operationName)) {
      visibilty = true;
    }

    if (!visibilty) {
      this.render.setStyle(this.el.nativeElement, 'display', 'none')
    }

    if (this.apiCalled) {
      this.userService.viewApicalled();
      this.apiCalled = false;
      // window.localStorage.setItem("headerLoad","false")
      this.userService.viewApicalledstop();
    }
  }
}
