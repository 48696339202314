import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RedirectRequestHandler } from '@openid/appauth';
import { AuthorizationService } from '../../../Service/authorization.service';
import { AppRoutingModule } from '../../../app-routing.module';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { AppCookieService } from '../../../Service/app.cookie.service';
import { environment} from 'src/environments/environment';
@Component({
  selector: 'app-ping-bpost',
  templateUrl: './ping-bpost.component.html',
  styleUrls: ['./ping-bpost.component.scss']
})
export class PingBpostComponent implements AfterViewInit {
  constructor(public authorizationService: AuthorizationService, public router: Router, private cookie: AppCookieService) { }
  ngAfterViewInit() {

  }

  ngOnInit() {
    if (!this.cookie.get("accessToken") && !this.authorizationService.getParameterByName("code", window.location.href)) {
      this.authorizationService.loginReuseService();
    } else {
      this.authorizationService.getAccessToken();
    }
    setInterval(() => {
      this.authorizationService.isAccessTokenExpired();
    }, 2000);
  }

  signOut() {
    let token = window.localStorage.getItem('token')
    window.localStorage.clear();
    window.location.href =  environment.pingLogout + 'id_token=' + token + `&TargetResource=`+ environment.redirect_uri +`&client_id=lockerinternal`
  }
}
