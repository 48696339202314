import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../Service/partner.service';
import { AppService } from '../Service/app.service';
import { SpinnerService } from '../Service/spinner.service';
import { LangtransService } from 'src/app/Service/langtrans.service';

@Component({
  selector: 'app-maintenance-staff-list',
  templateUrl: './maintenance-staff-list.component.html',
  styleUrls: ['./maintenance-staff-list.component.scss']
})
export class MaintenanceStaffListComponent implements OnInit {
  addUserForm: any;
  submitted: boolean;
  partnerList: any;
  deleteId: string;
  langKey: any
  partnerName: any;
  constructor(private fb: FormBuilder,
     private router: Router,
     private partnerService: PartnerService,
     private langtransService: LangtransService,
     private appService: AppService,
     private toastr: ToastrService, 
     private spinner: SpinnerService) {
  }

  ngOnInit(): void {
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.langKey = data;
    })
    this.spinner.show();

    this.partnerService.fetchMaintenanceUser().subscribe((res) => {
      this.spinner.hide();
      this.partnerList = res
    }, (err: any) => {
      console.error(err);
      this.spinner.hide();
    })
  }
  getModalId(id, name) {
    this.deleteId = id
    this.partnerName = name
  }
  onDelete() {
    var obj = {
      id: this.deleteId
    }
    this.partnerService.deleteMaintenanceUser(obj).subscribe((res) => {
      if (res == 'Deleted successfully')
        this.translator(this.langKey, 'User deleted successfully', true);
        this.partnerList = this.partnerList.filter(item => item.id != this.deleteId)
    })
  }
  onEdit(partner) {
    this.router.navigate(['/maintenanceEditUser/', partner.id]);
  }
  addMaintenanceUser() {
    this.router.navigate(['/maintenanceAddUser'])
  }

  langaugeFetch(lang, key) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        this.toastr.error(res);
      }
    )
  }
  translator(lang, key, status) {
    this.langtransService.TranslateData(lang, key).subscribe((res) => {
      if (status) {
        this.toastr.success(res);
      } else {
        this.toastr.error(res);
      }
    })
  }
}
