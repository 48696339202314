<app-header></app-header>
<div #myDiv [appViewController]="{parcels:'list'}">
  <div>
    <div>
      <nav class="navbar navbar-expand-lg navbar-dark pr-1">
        <ul class="navbar-nav mr-0 ml-auto mt-0 mt-lg-0">
          <!-- <li class="nav-item">
            <input type="image" src="/assets/img/redIcon.png"
              style="margin-right:20px; margin-top:10px; margin-bottom: 10px;" name="submit" width="28" height="28"
              alt="submit" (click)="exportToCSV()" [appViewController]="{parcels:'exportList'}">
          </li> -->
           <li class="nav-item" *ngIf="!showDownload">
            <div style="margin-right:20px; margin-top:10px;height: 20px; margin-bottom: 10px"> </div>
          </li> 
          <li class="nav-item" *ngIf="showDownload">
            <input type="image" src="/assets/img/redIcon.png"
              style="margin-right:20px; margin-top:10px; margin-bottom: 10px;" name="submit" width="28" height="28"
              alt="submit" (click)="exportToCSV()" [appViewController]="{parcels:'exportList'}">
          </li>
        </ul>
      </nav>
    </div>
    <form [formGroup]="parcelForm" class="pl-4 pr-4">
      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="barcode">{{'Barcode'|translate}}</label>
          <input class="form-control form-control-sm" style="height: 35px;" formControlName="barcode" name="barcode"
            placeholder="code">
        </div>

        <div class="form-group col-md-3">
          <label for="parcelLockerName">{{'Parcel Locker Name'|translate}}</label>
          <ng-multiselect-dropdown class="form-control-sm" formControlName="parcelLockerName"
            [placeholder]="'-All-'|translate" [settings]="parcelLockerNamedropdownSettings"
            [data]="parcelLockerNamedropdownList" [(ngModel)]="parcelLockerNameselectedItems"
            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>
        <div class="form-group col-md-3">
          <label for="orderDate">{{'Order Date'|translate}}</label>
          <div class="card">
            <div formGroupName="orderDate" class="form-row card-body p-0">
              <div class="form-group col-md-6 mb-0" style="height: 35px;">
                <!-- <input class="form-control form-control-sm input-width" formControlName="orderDateFrom"
                name="dateOfPickupCustomerFrom" type="date" max="9999-12-31" style="height: 35px;"> -->
                <!-- <input class="form-control" bsDatepicker [bsConfig]="{dateInputFormat:'DD-MM-YYYY'}"> -->
                <div class="container">
                  <div class="row">
                    <div class="input-group">
                      <input style="height: 35px;" type="text" placeholder="dd-mm-yyyy" class="form-control"
                        formControlName="orderDateFrom" #orderDateFromInput bsDatepicker
                        [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                      <!-- bsDaterangepicker -->
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fas fa-calendar-alt" (click)="openDatePicker('orderDateFromInput')"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6 mb-0">
                <!-- <input class="form-control form-control-sm input-width" formControlName="orderDateTo"
                name="dateOfPickupCustomerTo" type="date" max="9999-12-31" style="height: 35px;"> -->
                <div class="input-group">
                  <input style="height: 35px;" type="text" placeholder="dd-mm-yyyy" class="form-control"
                    formControlName="orderDateTo" #orderDateToInput bsDatepicker
                    [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                  <!-- bsDaterangepicker -->
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fas fa-calendar-alt" (click)="openDatePicker('orderDateToInput')"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p *ngIf="f.orderDate && f.orderDate.invalid" class="text-danger">{{'Please add a valid from and to date'|translate}}</p>
        </div>
        <div class="form-group col-md-2">
          <label for="orderType">{{'Order Type'|translate}}</label>
          <ng-multiselect-dropdown class="form-control-sm" formControlName="orderType" [placeholder]="'-All-'|translate"
            [settings]="orderTypedropdownSettings" [data]="orderTypedropdownList" [(ngModel)]="orderTypeselectedItems"
            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" style="height: 35px;">
          </ng-multiselect-dropdown>
        </div>
        <!-- <div class="form-group col-md-1">
          <label for="acCode">{{'Product'|translate}}</label>
          <select class="form-control form-control-sm mr-sm-2" formControlName="product" name="product"
            placeholder="product" style="height: 35px;">
            <option value="" hidden>{{'Product'|translate}}</option>
            <option value="ANY">{{'Any'|translate}}</option> 
            <option value="REDIRECTED">{{'Redirected'|translate}}</option>
            <option value="REROUTED">{{'Rerouted'|translate}}</option>
          </select>
        </div> -->
        <div class="form-group col-md-2">
          <label for="product">{{'Product'|translate}}</label>
          <select class="form-control form-control-sm  mr-sm-2" formControlName="product"
            name="product" style="height: 35px;">
            <option selected value="">{{'Choose Option...'|translate}}</option>
            <option value="REDIRECTED">{{'Redirected'|translate}}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="dateOfPickupCustomer">{{'Date of Pickup Customer'|translate}}</label>
          <div class="card">
            <div  formGroupName="dateOfPickup" class="form-row card-body p-1">
              <div class="form-group col-md-6 mb-0">
                <!-- <input class="form-control form-control-sm" formControlName="dateOfPickupCustomerFrom"
                name="dateOfPickupCustomerFrom" type="date" max="9999-12-31"> -->
                <div class="input-group">
                  <input style="height: 35px;" type="text" placeholder="dd-mm-yyyy" class="form-control"
                    formControlName="dateOfPickupCustomerFrom" #dateOfPickupCustomerFromInput bsDatepicker
                    [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}"
                    placement="bottom left">
                  <!-- bsDaterangepicker -->
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fas fa-calendar-alt" (click)="openDatePicker('dateOfPickupCustomerFromInput')"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6 mb-0">
                <!-- <input class="form-control form-control-sm" formControlName="dateOfPickupCustomerTo"
                name="dateOfPickupCustomerTo" type="date" max="9999-12-31"> -->
                <div class="input-group">
                  <input style="height: 35px;" type="text" placeholder="dd-mm-yyyy" class="form-control"
                    formControlName="dateOfPickupCustomerTo" #dateOfPickupCustomerToInput bsDatepicker
                    [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                  <!-- bsDaterangepicker -->
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fas fa-calendar-alt" (click)="openDatePicker('dateOfPickupCustomerToInput')"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p *ngIf="f.dateOfPickup && f.dateOfPickup.invalid" class="text-danger">{{'Please add a valid from and to date'|translate}}</p>
        </div>
        <div class="form-group col-md-3">
          <label for="dateOfPickupCourier">{{'Date Courier Pickup'|translate}}</label>
          <div class="card">
            <div formGroupName="dateOfPickupCourier"  class="form-row card-body p-1">
              <div class="form-group col-md-6 mb-0">
                <!-- <input class="form-control form-control-sm" formControlName="dateOfPickupCourierFrom"
                name="dateOfPickupCustomerFrom" type="date" max="9999-12-31"> -->
                <div class="input-group">
                  <input style="height: 35px;" type="text" placeholder="dd-mm-yyyy" class="form-control"
                    formControlName="dateOfPickupCourierFrom" #dateOfPickupCourierFromInput bsDatepicker
                    [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                  <!-- bsDaterangepicker -->
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fas fa-calendar-alt" (click)="openDatePicker('dateOfPickupCourierFromInput')"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6 mb-0">
                <!-- <input class="form-control form-control-sm" formControlName="dateOfPickupCourierTo"
                name="dateOfPickupCustomerTo" type="date" max="9999-12-31"> -->
                <div class="input-group">
                  <input style="height: 35px;" type="text" placeholder="dd-mm-yyyy" class="form-control"
                    formControlName="dateOfPickupCourierTo" #dateOfPickupCourierToInput bsDatepicker
                    [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                  <!-- bsDaterangepicker -->
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fas fa-calendar-alt" (click)="openDatePicker('dateOfPickupCourierToInput')"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p *ngIf="f.dateOfPickupCourier && f.dateOfPickupCourier.invalid" class="text-danger">{{'Please add a valid from and to date'|translate}}</p>
        </div>
        <div class="form-group col-md-3">
          <label for="dateOfDrop">{{'Date Courier Delivery'|translate}}</label>
          <div class="card">
            <div formGroupName="dateOfDrop" class="form-row card-body p-1">
              <div class="form-group col-md-6 mb-0">
                <!-- <input class="form-control form-control-sm" formControlName="dateOfDropFrom"
                name="dateOfPickupCustomerFrom" type="date" max="9999-12-31"> -->
                <div class="input-group">
                  <input style="height: 35px;" type="text" placeholder="dd-mm-yyyy" class="form-control"
                    formControlName="dateOfDropFrom" #dateOfDropFromInput bsDatepicker
                    [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                  <!-- bsDaterangepicker -->
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fas fa-calendar-alt" (click)="openDatePicker('dateOfDropFromInput')"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6 mb-0">
                <!-- <input class="form-control form-control-sm" formControlName="dateOfDropTo" name="dateOfPickupCustomerTo"
                type="date" max="9999-12-31"> -->
                <div class="input-group">
                  <input style="height: 35px;" type="text" placeholder="dd-mm-yyyy" class="form-control"
                    formControlName="dateOfDropTo" #dateOfDropToInput bsDatepicker
                    [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                  <!-- bsDaterangepicker -->
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fas fa-calendar-alt" (click)="openDatePicker('dateOfDropToInput')"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p *ngIf="f.dateOfDrop && f.dateOfDrop.invalid" class="text-danger">{{'Please add a valid from and to date'|translate}}</p>
        </div>

        <div class="form-group col-md-3" *ngIf="partnerDropdown">
          <label for="partnerName">{{'Partner'|translate}}</label>
          <ng-multiselect-dropdown class="form-control-sm" style="height: 38px;" formControlName="partnerName" [placeholder]="'Partner'|translate"
            [settings]="partnerNamedropdownSettings" [data]="partnerNamedropdownList"
            [(ngModel)]="partnerNameselectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="collapse" id="collapseExample">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="customerName">{{'Customer Name'|translate}}</label>
            <input class="form-control form-control-sm" formControlName="customerName" name="customerName"
              placeholder="eg: %Fred%">
          </div>

          <div class="form-group col-md-4">
            <label for="customerEMail">{{'Customer E-mail'|translate}}</label>
            <input class="form-control form-control-sm" formControlName="customerEMail" name="customerEMail"
              placeholder="eg: %Fred@hotmail.com%">
          </div>
          <div class="form-group col-md-4">
            <label for="senderEmail">{{'Sender Email'|translate}}</label>
            <input class="form-control form-control-sm" formControlName="senderEmail" name="senderEmail"
              placeholder="eg: %Fred@hotmail.com%">
          </div>
          <!-- <div class="form-group col-md-3">
          <label for="courier">Courier</label>
          <input type="text" class="form-control form-control-sm" formControlName="courier" name="courier"
            placeholder="bpost, GLS, Other">
        </div> -->
          <div class="form-group col-md-4">
            <label for="senderName">{{'Sender Name'|translate}}</label>
            <input class="form-control form-control-sm" formControlName="senderName" name="senderName"
              placeholder="eg: %zalando%">
          </div>
          <!-- </div>
      <div class="form-row"> -->
          <!-- <div class="form-group col-md-3">
          <label for="backupAttributed">Backup attributed to parcel </label>
          <input class="form-control form-control-sm" formControlName="backupAttributed" name="backupAttributed"
            placeholder="Backup attributed to parcel" readonly>
        </div> -->
          <div class="form-group col-md-4">
            <label for="reservationRequest">{{'Reservation Request Successful'|translate}}</label>
            <select class="form-control form-control-sm  mr-sm-2" formControlName="reservationRequest"
              name="reservationRequest">
              <option selected value="">{{'Choose Option...'|translate}}</option>
              <option value="true">{{'Yes'|translate}}</option>
              <option value="false">{{'No'|translate}}</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="lastStatus">{{'Last Status of Parcel'|translate}}</label>
            <ng-multiselect-dropdown class="form-control-sm" formControlName="lastStatus"
              [placeholder]="'-All-'|translate" [settings]="lastStatusdropdownSettings" [data]="lastStatusdropdownList"
              [(ngModel)]="lastStatusselectedItems" (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)">
            </ng-multiselect-dropdown>
          </div>
          <div class="form-group col-md-4 elemMargin">
            <label for="excludeCancelledOrders">{{'Exclude Cancelled Orders'|translate}}</label>
            <select class="form-control form-control-sm  mr-sm-2" formControlName="excludeCancelledOrders"
              name="excludeCancelledOrders">
              <option selected value="">{{'Choose Option...'|translate}}</option>
              <option value="true">{{'Yes'|translate}}</option>
              <option value="false">{{'No'|translate}}</option>
            </select>
          </div>
          <!-- </div>
      <div class="form-row"> -->
          <div class="form-group col-md-4 elemMargin">
            <label for="lockerType">{{'Locker Type'|translate}}</label>
            <select class="form-control form-control-sm  mr-sm-2" formControlName="lockerProvider"
              name="lockerProvider">
              <option selected value="">{{'Choose Option...'|translate}}</option>
              <option value="Lean">{{'Lean'|translate}}</option>
              <option value="Cubee">{{'Classic'|translate}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <button class="btn btn-danger mr-3 mt-2" type="submit"
              (click)="onSubmit()">{{'Filter'|translate}}</button>
            <button class="btn btn-danger mr-3 mt-2" type="button" data-toggle="collapse"
              data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
              {{'Advanced Filters'|translate}}</button>
            <button class="btn btn-outline-danger mt-2" type="submit" (click)="onReset()">{{'Reset'|translate}}</button>

          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="container-fluid">
    <div class="col-sm-md-xl-12">
      <div class="table-responsive pl-2 pr-2">
        <table datatable [dtOptions]="dtOptions" *ngIf="result"
          class="table table-sm table-bordered table-hover row-border hover" style="text-align:center;">
          <caption></caption>
          <thead class="thead-dark">
            <tr>
              <th scope="col">{{'Barcode'|translate}}</th>
              <!-- <th scope="col">Flow</th> -->
              <th scope="col">{{'Order Date'|translate}}</th>
              <th scope="col">{{'Parcel Locker Name'|translate}}</th>
              <th scope="col">{{'View Status in Etracker'|translate}}</th>
              <th scope="col">{{'Status'|translate}}</th>
              <!-- <th scope="col">Product</th> -->
              <!-- <th scope="col">Partner</th> -->
            </tr>
          </thead>
          <tbody *ngIf="result?.length != 0">
            <tr *ngFor="let res of result;">
                <td>
                  <a [routerLink]="['/parcels/', res.id]">{{res.barcode}}</a>
                </td>
                <!-- <td>{{res.parcelFlow || '-'}}</td> -->

                <td *ngIf="res.reservation.date"><span style="display: none;">{{res.reservation.date}}</span>
                  {{res.reservation.date | utctolocaldate:'DD/MM/YYYY HH:mm:ss' }}</td>
                <td *ngIf="!res.reservation.date">-</td>

                <td *ngIf="res.deliveryPoint.name">{{res.deliveryPoint.name}}</td>
                <td *ngIf="!res.deliveryPoint.name">-</td>

                <!-- <td *ngIf="res.physicalStatus.date">{{res.physicalStatus.date | date:'M/d/yyyy HH:mm:ss'}}</td>
            <td *ngIf="!res.physicalStatus.date">-</td> -->
                <td><a href="{{getetrackerUrl(res.barcode)}}" target="_blank">VIEW DETAILS</a></td>

                <td *ngIf="res.physicalStatus.eventCode && res.physicalStatus.eventSubCode">
                  {{res.physicalStatus.eventCode}}</td>
                <td *ngIf="!res.physicalStatus.eventCode || !res.physicalStatus.eventSubCode">-</td>
                <!-- <td *ngIf="res.product">{{res.product}}</td>
            <td *ngIf="!res.product">null</td> -->
              </tr>
          </tbody>
          <tbody *ngIf="result?.length == 0">
            <tr>
              <td colspan="5" class="no-data-available">No data!</td>
            </tr>
          <tbody>

        </table>

      </div>
    </div>
  </div>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>