import { Injectable, Inject, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { customEnvironment } from 'src/environments/environment';
import { AppCookieService } from './app.cookie.service';
import {
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  RedirectRequestHandler,
  Requestor,
  FetchRequestor,
  StringMap,
  TokenRequest,
  TokenResponse,
  GRANT_TYPE_AUTHORIZATION_CODE,
  AppAuthError,
  AuthorizationServiceConfigurationJson
} from '@openid/appauth';

import { BehaviorSubject, Observable, combineLatest, forkJoin } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { TokenResponseJson } from '@openid/appauth';
import * as jwt_decode from 'jwt-decode';
import { UserInfo } from '../userinfo';
import { AuthorizationConfig } from './authorization_config';
import { AuthorizationConfigExternal } from './authorization_config_external';
import { Router } from '@angular/router';
import { userEnvironment } from 'src/environments/environment';
import { UserService } from '../Service/user.service';
import { EventEmitter } from 'events';
const LS_ISSUER_URI = 'authorization.service.issuer_uri';
const LS_USER_INFO = 'authorization.service.user_info';
const LS_OPENID_CONFIG = 'authorization.service.parsed_openid_configuration';
const LS_TOKEN_RESPONSE = 'authorization.service.token_response';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class AuthorizationService {

  private notifier = new AuthorizationNotifier();
  private authorizationHandler = new RedirectRequestHandler();
  private tokenHandler = new BaseTokenRequestHandler();
  public status: string;

  userIsLogged: Boolean;
  private _tokenResponses: BehaviorSubject<TokenResponse>;
  private _userInfos: BehaviorSubject<UserInfo>;
  private _serviceConfigs: BehaviorSubject<AuthorizationServiceConfiguration>;
  currentDdsUser = new BehaviorSubject(null); // {1}
  currentUserData$ = new BehaviorSubject({});
  requestInfo: any = null;
  responseInfo: any = null;
  partnerStatus: any;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    public router: Router,
    private requestor: Requestor,
    @Inject('AuthorizationConfig') private environment: AuthorizationConfig,
    @Inject('AuthorizationConfigExternal') private pingEnvironment: AuthorizationConfigExternal,
    private cookie: AppCookieService,
  ) {
    //this.authorizationHandler.setAuthorizationNotifier(this.notifier);
    // attempt to restore previous values of the metadata config, token response, and user info
    let authorizationServiceConfiguration: AuthorizationServiceConfiguration | null = null;
    let tokenResponse: TokenResponse | null = null;
    let userInfo: UserInfo | null = null;
    let codeVerifier: string = null;

    // verify that we are still working with the same IDP, since a reload may
    // have been due to an underlying configuration change
    if (environment.issuer_uri === window.localStorage.getItem(LS_ISSUER_URI)) {
      const serviceConfigJSON = JSON.parse(
        window.localStorage.getItem(LS_OPENID_CONFIG));
      authorizationServiceConfiguration = serviceConfigJSON &&
        new AuthorizationServiceConfiguration(serviceConfigJSON);

      const tokenResponseJSON = JSON.parse(window.localStorage.getItem(LS_TOKEN_RESPONSE));
      tokenResponse = tokenResponseJSON && new TokenResponse(tokenResponseJSON);
      userInfo = JSON.parse(window.localStorage.getItem(LS_USER_INFO));
      this.userIsLogged = true;
      //new check jj
      if (localStorage.getItem("idToken") && !localStorage.getItem("ddsLoaded")) {
        this.router.navigate(['user/forbidden']);
      }
      //this.loadDdsUser()
    } else {
      // new issuer (or first run, or cleared session)
      // make sure we store the issuer, and have no other state
      window.localStorage.setItem(LS_ISSUER_URI, environment.issuer_uri);
      window.localStorage.removeItem(LS_OPENID_CONFIG);
      window.localStorage.removeItem(LS_USER_INFO);
      window.localStorage.removeItem(LS_TOKEN_RESPONSE);
    }
    // create subjects with the current values (or null)
    this._tokenResponses = new BehaviorSubject(tokenResponse);
    this._serviceConfigs = new BehaviorSubject(authorizationServiceConfiguration);
    this._userInfos = new BehaviorSubject(userInfo);
    // update local storage on changes
    this._serviceConfigs.subscribe((config: AuthorizationServiceConfiguration) => {
      window.localStorage.setItem(LS_OPENID_CONFIG, config && JSON.stringify(config.toJson()));
    });
    this._tokenResponses.subscribe((token: TokenResponse) => {
      window.localStorage.setItem(LS_TOKEN_RESPONSE, token && JSON.stringify(token.toJson()));
    });
    this._userInfos.subscribe((info: UserInfo) => {
      window.localStorage.setItem(LS_USER_INFO, info && JSON.stringify(info));
    });
    // monitor changes in metadata/tokens to possibly clear dependent values,
    // and to fetch userInfo.
    combineLatest(this._serviceConfigs, this._tokenResponses)
      .subscribe(
        ([configuration, token]: [AuthorizationServiceConfiguration, TokenResponse]) => {

          // if the service config is cleared, we need to invalidate any TokenResponse/userInfo
          if (configuration == null) {
            if (token != null) {
              this._tokenResponses.next(null);
            }
            this._userInfos.next(null);
            return;
          }

          // if the token is cleared, assume userinfo is invalidated too
          if (token == null) {
            this._userInfos.next(null);
            return;
          }

          // if we don't have a user info endpoint, we can't fetch user info
          if (configuration.userInfoEndpoint == null) {
            this._userInfos.next(null);
            return;
          }

          // fetch user info, if none
          if (this._userInfos.value == null) {
            const accessToken = token.accessToken;
            this.requestor.xhr<UserInfo>({
              url: configuration.userInfoEndpoint,
              method: 'GET',
              dataType: 'json',
              headers: {
                'Authorization': `Bearer ${accessToken}`
              }
            }).then((userinfo) => {
              this._userInfos.next(userinfo);
            });
          }
        });

    // start fetching metadata
    if (authorizationServiceConfiguration == null) {
      this.fetchServiceConfiguration(environment);
    }
  }

  setCurrentDdsUser(user) {
    this.currentDdsUser.next(user);
    //PLU-1597 

    // if (user) {
    //   if (user.partner.type.toUpperCase() === 'OWNER') {
    //     this.router.navigate(['parcels'])

    //   }
    //   else if (user.partner.type.toUpperCase() === 'CARRIER') {
    //     this.router.navigate(['partnertools']);

    //   }
    // }
  }

  async loadDdsUser(): Promise<any> {
    this.userService.me()
      .subscribe(
        (res:any) => {
          this.setCurrentDdsUser(res);
          this.userService.setCurrentUser(res);
          localStorage.setItem('currentUser',JSON.stringify(res));
          localStorage.setItem('partner',JSON.stringify(res?.partner));
          localStorage.setItem("ddsLoaded", "yes");
        },
        err => {
          this.userIsLogged = false
          if (err.status === 401) {
            this.setCurrentDdsUser(false)
            this.router.navigate(['user/forbidden'])
            return;
          } else {
            this.setCurrentDdsUser(null)
          }
        }
      )
  }

  get issuerUri(): string {
    return this.environment.issuer_uri;
  }

  public isLoggedIn(): Boolean {
    return this.userIsLogged
  }

  public serviceConfiguration(): Observable<AuthorizationServiceConfiguration> {
    return this._serviceConfigs.asObservable().pipe(distinctUntilChanged());
  }

  public tokenResponse(): Observable<TokenResponse> {
    return this._tokenResponses.asObservable().pipe(distinctUntilChanged());
  }

  public userInfos(): Observable<UserInfo> {
    return this._userInfos.asObservable().pipe(distinctUntilChanged());
  }

  authorize(): void {
    this._serviceConfigs
      .pipe(filter((value: any) => value != null))
      .pipe(take(1))
      .subscribe((configuration: AuthorizationServiceConfiguration) => {
        const scope = this.environment.scope || 'openid';

        // create a request
        const request = new AuthorizationRequest({
          client_id: this.environment.client_id,
          redirect_uri: this.environment.redirect_uri,
          scope: scope,
          response_type: 'token',
          extras: this.environment.extras
        });
        this.authorizationHandler.performAuthorizationRequest(configuration, request);
      });
  }

  authorizeExternal(): void {
    this._serviceConfigs
      .pipe(filter((value: any) => value != null))
      .pipe(take(1))
      .subscribe((configuration: AuthorizationServiceConfiguration) => {
        AuthorizationServiceConfiguration.fetchFromIssuer(this.pingEnvironment.issuer_uri, new FetchRequestor())
          .then((response: any) => {
            // this.configuration = response;
            const authRequest = new AuthorizationRequest({
              client_id: this.pingEnvironment.client_id,
              redirect_uri: this.pingEnvironment.redirect_uri,
              scope: this.pingEnvironment.scope,
              response_type: 'code',
              state: undefined,
              extras: this.pingEnvironment.extras
            });
            this.authorizationHandler.performAuthorizationRequest(response, authRequest);
          })
          .catch(error => {
            console.error('error', error);
          });
      });

  }

  generateLargeNumber(minDigits, maxDigits) {
    // Vérifier que les arguments sont valides
    if (minDigits < 1 || maxDigits < minDigits) {
        throw new Error("Les valeurs des arguments sont incorrectes");
    }
    
    // Générer une longueur aléatoire entre minDigits et maxDigits
    const length = Math.floor(Math.random() * (maxDigits - minDigits + 1)) + minDigits;
    
    // Générer la chaîne de chiffres aléatoires
    let numberStr = '';
    for (let i = 0; i < length; i++) {
        // Ajouter un chiffre aléatoire (de 0 à 9) à la chaîne
        numberStr += Math.floor(Math.random() * 10);
    }
    
    // just return the number as a string
    return numberStr;

    // Convertir la chaîne en un BigInt pour gérer de très grands nombres
    /*
    const largeNumber = BigInt(numberStr);
    return largeNumber;
    */
}

  async loginReuseService() {
    let code_challenge = '';
    let state = '';
    /** To Generate the Random Numbers  */
    // challenge number (as code verifier too) need to be between 43 and 128 digits !
    code_challenge = this.generateLargeNumber(43,128);
    //console.log('code_challenge after generation:', code_challenge);

    for (var i = 0; i < 43; i++) {
      state += Math.floor(Math.random() * 9);
    }
    /** Post data Params */
    var objdata = {
      "client_id": this.environment.client_id,
      "response_type": "code",
      "scope": "openid",
      'redirect_uri': userEnvironment.baseUrl,
      "code_challenge": code_challenge,
      "state": state
    };

    this.cookie.delete('code_challenge');
    this.cookie.set('code_challenge', code_challenge);

    var url = this.environment.pingAuthorizationUrl + '?' + this.serializeData(objdata);
    window.open(url, "_self", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    this.cookie.set('windowOpen', 'true');
    //this.cookie.dumpAllCookies('loginReuseService');
  }

  private async fetchServiceConfiguration(configuration: AuthorizationConfig) {
    const response = await AuthorizationServiceConfiguration.fetchFromIssuer(configuration.issuer_uri, this.requestor);
    this._serviceConfigs.next(response);
  }

  signOut(): void {
    this.userIsLogged = false
    this.setCurrentDdsUser(null)
    let token = window.localStorage.getItem('token')
    let tokenInfo = this.getDecodedAccessToken1(token);
    window.localStorage.clear();
    this.removeCookie();
    if (tokenInfo) {
      if (tokenInfo.client_id === "nlp_web_ciam_np") {
        window.location.href =  this.pingEnvironment.pingLogout + 'id_token=' + token + '&TargetResource='+ this.pingEnvironment.redirect_uri +'&client_id=nlp_web_ciam_np'
      } else {
        window.location.href = this.environment.pingLogout + 'id_token=' + token + '&TargetResource='+ this.environment.redirect_uri +'&client_id=lockerinternal'
      }
    }
  }

  signOutForbidden(): void {
    this.userIsLogged = false
    this.setCurrentDdsUser(null)
    let token = window.localStorage.getItem('token')
    let tokenInfo = this.getDecodedAccessToken1(token);
    window.localStorage.clear();
    if (tokenInfo) {
      if (tokenInfo.client_id === "lockerexternal") {
        window.location.href = this.pingEnvironment.pingLogout +  'id_token=' + token + `&TargetResource=` + this.pingEnvironment.pingLogout + `&client_id=NLP_reg_np`
      } else {
        window.location.href = this.environment.pingLogout + 'id_token=' + token + `&TargetResource=` + this.environment.redirect_uri + `&client_id=lockerinternal`
      }
    }
  }

  completeAuthorizationRequest(token) {
    let tokenInfo = this.getDecodedAccessToken1(token);
    let isUserLogged = this.userIsLogged
    if (tokenInfo) {
      isUserLogged = true
      this.loadDdsUser()
      return tokenInfo
    } else {
      isUserLogged = false
      return tokenInfo
    }
  }

  /*
  completeCodeAuthorization(clientId, redirectUrl, code) {
    // let extras = null;
    // this.notifier.setAuthorizationListener((request, response, error) => {
    //   console.log('Authorization request complete ', request, response, error);
    //   if (response) {
    //     this.requestInfo = request;
    //     console.log(`Authorization Code  ${response.code}`);

    //     if (this.requestInfo && this.requestInfo.internal) {
    //       extras = {};
    //       extras.code_verifier = this.requestInfo.internal.code_verifier;
    //     }
    //   }
    //});

    // if() {
    //   extras = {};
    //   extras.code_verifier = this.requestInfo.internal.code_verifier;
    // }

    // this.notifier.setAuthorizationListener((request, response, error) => {
    //   console.log('Authorization request complete ', request, response, error);
    //   if (response) {
    // this.request = request;
    // this.response = response;
    // this.code = response.code;
    // console.log(`Authorization Code  ${response.code}`);

    // let extras = null;
    // if (this.request && this.request.internal) {
    //   extras = {};
    //   extras.code_verifier = this.request.internal.code_verifier;
    // }

    //     const tokenRequest = new TokenRequest({
    //       client_id: this.pingEnvironment.client_id,
    //       redirect_uri: this.pingEnvironment.redirect_uri,
    //       grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
    //       code: code,
    //       refresh_token: undefined,
    //       extras
    //     });

    //     AuthorizationServiceConfiguration.fetchFromIssuer(this.pingEnvironment.issuer_uri, new FetchRequestor())
    //       .then((oResponse: any) => {
    //         return this.tokenHandler.performTokenRequest(oResponse, tokenRequest);
    //       })
    //       .then((oResponse) => {
    //         localStorage.setItem('access_token', oResponse.accessToken);
    //         this.router.navigate(['/profile']);
    //       })
    //       .catch(oError => {
    //         console.log('error', error);
    //       });
    //   }
    // });

    // this._serviceConfigs
    // .pipe(filter((value: any) => value != null))
    // .pipe(take(1))
    // .subscribe((configuration: AuthorizationServiceConfiguration) => {
    //   console.log('pingEnvironment', this.pingEnvironment);
    //   const tokenRequest = new TokenRequest({
    //     client_id: clientId,
    //     redirect_uri: redirectUrl,
    //     grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
    //     code: code,
    //     extras
    //   });
    //   this.tokenHandler.performTokenRequest(configuration, tokenRequest)
    //   .then(response => {
    //       window.localStorage.setItem('token', response.accessToken);
    //       let res = this.completeAuthorizationRequest(response.accessToken)
    //       console.log('res', res);
    //       window.localStorage.setItem('userData', JSON.stringify(res));
    //   });
    // });
  }
  */

  getDecodedAccessToken1(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
  /** Serilaize */
  serializeData = function (obj) {
    var str = [];
    for (var p in obj)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    return str.join("&");
  }
  /*** Getting the redirect URL* */
  getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  /** Exp time */
  isAccessTokenExpired() {
    let isexpired = false;
    let accessToken = localStorage.getItem('token');
    if (!accessToken) return true;
    let decodeToken = this.parseJwt(accessToken);
    var validTill = new Date(decodeToken.exp * 1000);
    var now = new Date();
    if (now > validTill) {
      // localStorage.removeItem('token');
      // let obj = {
      //   'grant_type': 'refresh_token',
      //   "refresh_token": localStorage.getItem('refreshToken'),
      //   "client_id": this.environment.client_id,
      // }
      // the tocken is expired
      // this.oauthToken(obj);

      isexpired = true;
      this.signOut();
    }
    return isexpired;
  }

  /** oauth Token */
  async oauthToken(obj) {
    let options = {
      withCredentials: false,
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    await this.http.post(this.environment.pingTokenUrl, this.serializeData(obj), options).toPromise()
      .then(res => {
        let encodedAccessToken = this.encodeAccessToken(res['access_token']);
        this.cookie.set('encodedAccessToken', encodedAccessToken);
        this.cookie.set('accessToken', res['access_token']);
        this.cookie.delete('code_challenge')
        this.userProfile();
        localStorage.setItem('token', res['access_token']);
        localStorage.setItem('refreshToken', res['refresh_token']);
        localStorage.setItem('idToken', res['id_token']);
        let decodeToken = this.parseJwt(res['access_token']);
        localStorage.setItem('expiryTime', decodeToken.exp);
      })
      .catch(error => {
        console.error('error', error);
      });
  }
  async getAccessToken() {
    //this.cookie.dumpAllCookies('getAccessToken');
    let code_challenge = this.cookie.get('code_challenge');
    //console.log('getAccessToken code_challenge :', code_challenge);

    /** Get the code Value from Redirect URL */
    let auth_code_val = this.getParameterByName("code", window.location.href)
    /** Post data Params */
    var obj = {
      'code': auth_code_val,
      'grant_type': 'authorization_code',
      'client_id': this.environment.client_id,
      'code_verifier': code_challenge,
      'redirect_uri': userEnvironment.baseUrl
    }
    this.oauthToken(obj);
  }
  parseJwt(token) {
    if (!token) return null
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };
  encodeAccessToken(accesstoken: string) {
    let encodedAccessToken = CryptoJS.AES.encrypt(accesstoken, 'locker key 123456').toString();
    localStorage.setItem('encodedAccessToken', encodedAccessToken);
    return encodedAccessToken;
  }

  getDecodedAccessToken() {
    var bytes = CryptoJS.AES.decrypt(localStorage.getItem('encodedAccessToken'), 'locker key 123456');
    var accessToken = bytes.toString(CryptoJS.enc.Utf8);
    return accessToken;
  }
  logout() {
    this.removeCookie()
    let obj = {
      "id_token": localStorage.getItem('idToken'),
      'TargetResource': userEnvironment.baseUrl,
      "client_id": this.environment.client_id,
    }
    let url = this.environment.pingLogout + this.serializeData(obj);
    // var win = window.open(url, '_blank');
    window.open(url, "_self");

  }
  removeCookie() {
    this.cookie.delete('windowOpen');
    this.cookie.delete('code_challenge');
    this.cookie.delete('accessToken');
    this.cookie.delete('firstName');
    this.cookie.delete('lastName');
    this.cookie.delete('userID');
    this.cookie.delete('departmentName');
    this.cookie.delete('title');
    localStorage.removeItem('idToken');
    localStorage.removeItem('refreshToken');
  }

  userProfile() {

    let options = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.getDecodedAccessToken())
    };
    this.http
      .get(this.environment.pingUserProfileUrl, options)
      .toPromise()
      .then(res => {
        localStorage.setItem('userData', JSON.stringify(res));
        localStorage.setItem('userID', res['uid']);
        localStorage.setItem('lastName', res['family_name']);
        localStorage.setItem('userID', res['uid']);
        localStorage.setItem('departmentName', res['department']);
        localStorage.setItem('title', res['given_name']);
        this.setPrivilegeValue(res);
        this.loadDdsUser();
        // this.router.navigate(['parcels']);
      })
  }

  async setPrivilegeValue(response: any) {
    let userEmail: any

    if (response.email) {
      userEmail = response.email
    }
    else if(response.Email ) {
      userEmail = response.Email
    }
    else if (response.mail) {
      userEmail = response.mail
    }
    
    let filterVal = {
      email: userEmail,
      name: undefined,
      groups: undefined,
    }

    let reqArray = [this.userService.fetchMany(filterVal).toPromise(), this.userService.getUsergroup().toPromise()];
    let [userRole, currentGroups]:any = await Promise.all(reqArray);
    
    let userDetails = (userRole as Array<any>).find(o => o.email.toLowerCase() === userEmail.toLowerCase());
    
    if (userDetails && userDetails.partner) {
      this.partnerStatus = userDetails.partner
      window.localStorage.setItem('partnerDetails', JSON.stringify(userDetails.partner))
      if (userDetails.partner?.type?.toUpperCase() === 'OWNER') {
        window.localStorage.setItem('redirectURL','partnertools')
      }
      else  {
        window.localStorage.setItem('redirectURL','parcels')

      }
    }

    let currentUserRoles = userDetails.groups.map(element => element.name)
      ///  show partner tool tab as it is visible for partnertype DELIVERY and hide for type OWNER
      for (let i = 0; i < currentUserRoles.length; i++) {
        let filteredVal = currentGroups.filter((element) => {
          if (element.userGroups != null) {
            if (element.userGroups.title == currentUserRoles[i]) {
              return element
            }
          }
  
        })
  
        if (filteredVal.length == 0) {
          continue
        }
  
        let checklist = filteredVal[0].userGroups.checklistData;
        if (userDetails.partner?.type?.toUpperCase() === 'OWNER') {
          checklist.filter(x => x['partnerTool'])[0]['partnerTool'].viewPartnerTool = true
  
        }
        if (userDetails.partner?.type?.toUpperCase() === 'DELIVERY') {
          checklist.filter(x => x['partnerTool'])[0]['partnerTool'].viewPartnerTool = false
  
        }
      }
          ///End 
    window.localStorage.setItem('currentRoles', JSON.stringify(currentUserRoles))
    window.localStorage.setItem('currentGroups', JSON.stringify(currentGroups))
    // this.setRoute(currentUserRoles,currentGroups)
    if (currentUserRoles.includes("partnertoolgroup")) {
      if (currentUserRoles.length == 1) {
        this.router.navigate(['partnertools']);
      }
      else {
        this.setRoute(currentUserRoles, currentGroups)
      }

    }
    else {
        this.setRoute(currentUserRoles, currentGroups)
    }
    //})

    //end promise.all approach
    // let test=await this.userService.fetchMany(filterVal).toPromise()

    //   let userRole:any= test

    //      let userDetails = userRole.find(o => o.email === userEmail);

    //      let currentUserRoles= userDetails.groups.map(element => element.name)
    //      window.localStorage.setItem('currentRoles', JSON.stringify(currentUserRoles))

    //       let res=await this.userService.getUsergroup().toPromise()

    //     window.localStorage.setItem('currentGroups', JSON.stringify(res))
    //     if(currentUserRoles.includes("partnertoolgroup"))
    //     {
    //       if(currentUserRoles.length==1)
    //       {
    //         this.router.navigate(['partnertools']);
    //       }
    //       else{
    //         this.router.navigate(['parcels']);
    //       }

    //     }
    //     else{
    //       this.router.navigate(['parcels']);
    //     }

    // this.userService.fetchMany(filterVal).subscribe(res=>{
    //     let userRole:any= res
    //     let userDetails = userRole.find(o => o.email === userEmail);
    //     let currentUserRoles= userDetails.groups.map(element => element.name)
    //    window.localStorage.setItem('currentRoles', JSON.stringify(currentUserRoles))
    //    this.router.navigate(['parcels']);

    //   })

    //   this.userService.getUsergroup().subscribe(r=>{
    //     window.localStorage.setItem('currentGroups', JSON.stringify(r))
    //   })
  }
  setRoute(currentUserRoles: any, currentGroups: any) {
    let groupHeading = {}
    for (let i = 0; i < currentUserRoles.length; i++) {
      let filteredVal = currentGroups.filter((element) => {
        if (element.userGroups != null) {
          if (element.userGroups.title == currentUserRoles[i]) {
            return element
          }
        }

      })

      if (filteredVal.length == 0) {
        continue
      }

      let checklist = filteredVal[0].userGroups.checklistData;
      let titleKeys = checklist.map((obj) => {
        for (let i in obj) {
          return i
        }


      })
      if (i == 0) {
        for (let i = 0; i < titleKeys.length; i++) {
          groupHeading[titleKeys[i]] = false
        }
      }

      checklist.forEach((object, index) => {
        let trueCount = 0
        for (let key in object[titleKeys[index]]) {
          if (object[titleKeys[index]][key] == true) {
            trueCount = trueCount + 1
          }

        }
        if (trueCount != 0) {
          if (!groupHeading[titleKeys[index]]) {
            groupHeading[titleKeys[index]] = true
          }
        }

      })

      // if(groupHeading['parcels'])
      // {
      //   return
      // }
    }

    if (localStorage.getItem('redirectURL')) {
      let url = localStorage.getItem('redirectURL');
      url ? this.router.navigate([url]):this.router.navigate(['parcels']);;
      return
    }
    
    if (groupHeading['parcels']) {
      this.router.navigate(['parcels']);
      return
    }
    else if (groupHeading['deliveryPoints']) {
      this.router.navigate(['lockerlist']);
      return
    }
    else if (groupHeading['users']) {
      this.router.navigate(['user/search']);
      return
    }
    else if (groupHeading['partner']) {
      this.router.navigate(['partners']);
      return
    }
    else if (groupHeading['staff']) {
      this.router.navigate(['postman']);
      return
    }
    else if (groupHeading['report']) {
      this.router.navigate(['lockerdownreport']);
      return
    }
    else if (groupHeading['partnerTool']) {
      this.router.navigate(['partnertools']);
      return
    }

  }

}
