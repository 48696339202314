<app-header></app-header>

<img src="/assets/img/bg2.jpg" alt="" class="bg2">
<!-- <div class="container vertical-center"> -->
<div class="container" [appViewController]="{report:'userOpsReport'}">
  <form [formGroup]="userOperationForm" class="container mat-card mb-6 mt-4 shadow bg-white rounded">
    <br>
    <p class="h4" style="text-align: center; margin-top: 10px; margin-bottom: 18px;">{{'User Operation
      Report'|translate}}</p>
    <div class="form-row">
      <div class="form-group col-md-3 date-picker" style="margin-left: 12%;">
        <label for="dateFrom">{{'Date From'|translate}}</label>
        <div class="card">
          <!-- <div class="form-row card-body p-0"> -->
          <div>
            <!-- <input class="form-control form-control-md input-width datepicker" formControlName="dateFrom"
              name="dateFrom" type="date" [max]="getToday()"> -->
              <div class="container">
                <div class="row">
                  <div class="input-group">
                    <input
                      style="height: 35px;"
                      type="text"
                      placeholder="dd-mm-yyyy"
                      class="form-control"
                      formControlName="dateFrom"
                      [maxDate]="maxDate"
                      #dateFromInput
                      bsDatepicker
                      [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                      <!-- bsDaterangepicker -->
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fas fa-calendar-alt" (click)="openDatePicker('dateFromInput')"></i>
                        </span>
                      </div>
                  </div>
                </div>
              </div>

            <!-- </div> -->
          </div>
        </div>
        <div class="col-12 text-center">
          <small *ngIf="isFromDate" class="form-text text-danger" style="padding-left: 0px;">{{'Please fill From Date'|translate}}</small>
        </div>
      </div>
      <div class="form-group col-md-3 date-picker">
        <label for="dateTo">{{'Date To'|translate}}</label>
        <div class="card">
          <!-- <div class="form-row card-body p-0"> -->
          <div class="widthclass">
            <!-- <input class="form-control form-control-md input-width datepicker" formControlName="dateTo" name="dateTo"
              type="date" [max]="getToday()"> -->
              <div class="container">
                <div class="row">
                  <div class="input-group">
                    <input
                      style="height: 35px;"
                      type="text"
                      placeholder="dd-mm-yyyy"
                      class="form-control"
                      formControlName="dateTo"
                      [maxDate]="maxDate"
                      #dateToInput
                      bsDatepicker
                      [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                      <!-- bsDaterangepicker -->
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fas fa-calendar-alt" (click)="openDatePicker('dateToInput')"></i>
                        </span>
                      </div>
                  </div>
                </div>
              </div>
          </div>
          <!-- </div> -->
        </div>
        <div class="col-12 text-center">
          <small *ngIf="isToDate" class="form-text text-danger" style="padding-left: 0px;">{{'Please Fill To Date'|translate}}</small>
        </div>
      </div>
      <div class="form-group col-md-3 dropdown">
        <label class="text-center">{{'Event Type'|translate}}</label>
        <!-- <ng-multiselect-dropdown class="form-control-sm" formControlName="eventType" [data]="eventTypedropdownList">
              </ng-multiselect-dropdown> -->
        <select class="form-control form-control-md dropdown" formControlName="eventType" name="eventType"
          placeholder="Event Type">
          <option value="" hidden>{{'Select'|translate}}</option>
          <option value="LOCKER OPENED">{{'Open Locker '|translate}}</option>
          <option  [appViewController]="{report:'CameraActions'}" value="CAMERA">{{'Camera Actions'|translate}}</option>
        </select>
      </div>
      <div class="container">
        <div class="row">
          <div class="col text-center mb-3 mt-3">
            <button class="btn btn-danger mb-3 mr-3" type="submit"
              (click)="onExport()">{{'Export'|translate}}</button>
            <button class="btn btn-outline-danger mb-3" (click)="onReset()">{{'Reset'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- </div> -->
