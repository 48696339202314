import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ddsEnvironment, pingEnvironment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { userEnvironment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public subject = new BehaviorSubject<any>(false);
  checklistData = [{
      deliveryPoints: {
        showList: false,
        exportList: false,
        showDetails: false,
        restartButton: false,
        takeOver: false,
        updateLockerDetails: false,
        showLogs: false,
        showDowntime: false,
        showTerminals: false,
        allowCommand: false,
        allowAction: false,
        displayLayout: false,
        showOnboard: false,
        enableCamera:false
      }
    },
    {
      parcels: {
        list: false,
        exportList: false,
        showDetails: false,
        changeParcelstatus: false,
        assignCompartment: false,
        optionQrPickup: false,
        optionChangeExpirationDate: false,
        optionProcessManually: false,
        optionMarkFraud: false,
        optionCancelParcel: false,
        optionResendMail: false,
        editTimeline: false,
        reserveLastmile: false,
        reserveFirstmile: false
      }
    }, {
      users: {
        showList: false,
        exportList: false,
        editUser: false,
        deleteUser: false,
        addUser: false,
        showGroup: false,
        createGroup: false,
        modifyGroup: false,
        deleteGroup: false,
      },
    }, {
      partner: {
        showList: false,
        addPartner: false,
        editPartner: false
      }
    },
    {
      staff: {
        postmanCode: false,
        maintenanceStaff: false,
        createStaff: false,
        editStaff: false,
        deleteStaff: false,
      },
    },
    {
      report: {
        downTimeReport: false,
        firstmileFailed: false,
        userOpsReport: false,
        CameraActions: false
      }
    },
    {
      partnerTool: {
        viewPartnerTool: false
      }
    }
  ]
  apiBaseUrl = `${ddsEnvironment.baseUrl}/user`
  currentUser: any = JSON.parse(localStorage.getItem('currentUser'));

  constructor(
    private http: HttpClient
  ) {
  }

  viewApicalled()
  {
    this.subject.next(true)
  }

  /**
   * Given a feature and an operation, check if the current user has the
   * necessary permission.
   *
   * This can also be used to check for permissions in a dotted notation,
   * for example: `checkPermission('feature.operation')`.
   *
   * @param feature
   * @param operation
   * @returns
   */
  checkPermission(feature: any, operation?: any) {
    const isDotted = feature.includes('.') && !operation;
    if (isDotted) {
      const [featureName, operationName] = feature.split('.');
      feature = featureName;
      operation = operationName;
    }

    if (!this.currentUser.permissions[feature]) return false;
    return !!this.currentUser.permissions[feature][operation]
  }

  viewApicalledstop()
  {
    this.subject.next(false)
  }

  setCurrentUser(value){
    this.currentUser = value;
  }

  me() {
    return this.http.get(`${this.apiBaseUrl}/me`)
  }

  fetchMany(query: any) {
    Object.keys(query).forEach(key => query[key] === undefined ? delete query[key] : {});
    return this.http.post(
      `${this.apiBaseUrl}/search?limit=500&offset=0`,query )
  }
  
  fetchManyUsers(body:Object,limit,offset): Promise <any> {
    return this.http.post(
      `${this.apiBaseUrl}/search?limit=${limit}&offset=${offset}&output=json`,body,{
        observe: 'response'
    }).toPromise()
  }
  fetchGroups() {
    return this.http.get(
      `${ddsEnvironment.baseUrl}/user/groups`)
  }
  addUser(body: any) {
    return this.http.post(
      `${ddsEnvironment.baseUrl}/user`,body)
  }

  addUsergroup(body: any) {
    return this.http.post(
      `${ddsEnvironment.baseUrl}/user/groups/createusergroup`,body)
  }

  getUsergroup()
  {
    return this.http.get(`${ddsEnvironment.baseUrl}/user/groups`)
  }

  deleteUser(id:any){
    return this.http.delete(
      `${ddsEnvironment.baseUrl}/user/${id}`)
  }
  deleteUsergroup(num){
    let obj={id:num}
    return this.http.delete(
      `${ddsEnvironment.baseUrl}/user/groups/modifyusergroup`,{params:obj})
  }
   fetchById(id:any){
    return  this.http.get(
      `${ddsEnvironment.baseUrl}/user/${id}`)
  }
  updateUser(body:any,id:any){

    return this.http.post(
      `${ddsEnvironment.baseUrl}/user/${id}`,body)
  }

  getFlowId()
  {
    const headers= new HttpHeaders()
    .set('Sec-Fetch-Mode',"navigate")
    return this.http.get(pingEnvironment.pingAuthorizationUrl + `?client_id=NLP_reg_np&response_type=token&scope=openid&redirect_uri=`+  pingEnvironment.redirect_uri +`&response_mode=pi.flow`,{headers})
  }

  intiateUserRegestration(id:any,pluginType:any)
  {
    const headers= new HttpHeaders()
    .set('X-XSRF-Header',"Test")
    .set('Cookie',`PF=${pluginType}`)
    return this.http.post( pingEnvironment.pingRegistrationUrl +  `/${id}?action=initiateRegistration`,null,{headers})
  }

  registerPingUser(id:any,body:any)
  {
    const headers= new HttpHeaders()
    .set('X-XSRF-Header',"Test")
    return this.http.post( pingEnvironment.pingRegistrationUrl + `/${id}?action=registerUser`,body,{headers})

  }
}
