<app-header></app-header>
<div #myDiv [appViewController]="{deliveryPoints:'showOnboard'}">
  <img src="/assets/img/bg2.jpg" alt="" class="bg2">


  <br>
  <br>
  <div class="col-12 text-secondary">
    <div class=" mat-card col-md-12 col-sm-12 col-xs-12 mx-auto">
      <h2 class="card-title text-center">{{'Upload File'|translate}}</h2>
      <form [formGroup]="uploadForm" (ngSubmit)="onUpload()">
        <div class="form-group">
          <div class="row">
            <label class="required col-sm-3 ml-4" for="acceptSoftReservations">{{'Soft Reservation'|translate}}</label>
            <select class="form-control col-sm-8" formControlName="acceptSoftReservations"
              name="acceptSoftReservations">
              <option selected value="">{{'Choose Option...'|translate}}</option>
              <option value="true">{{'Yes'|translate}}</option>
              <option value="false">{{'No'|translate}}</option>
            </select>
          </div>
          <!-- <small class="row">
            <small class="col-sm-3 ml-4"></small>
            <small *ngIf="submitted && formValidators.acceptSoftReservations.errors?.required"
              class="form-text text-muted col-sm-8">{{'please
              select soft Reservation'|translate}}</small>
          </small> -->
          <small class="row">
            <small class="col-sm-3 ml-4"></small>
            <small
              *ngIf="uploadForm.get('acceptSoftReservations').hasError('required') && uploadForm.get('acceptSoftReservations').touched"
              class="form-text text-muted col-sm-8">{{'please
              select soft Reservation'|translate}}</small>
          </small>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="required col-sm-3 ml-4" for="Date">{{'Live Date'|translate}}</label>
            <input class="form-control col-sm-8 form-control-md input-width datepicker" formControlName="livedate"
              name="livedate" type="date" [min]="getToday()">

          </div>
          <small class="row">
            <small class="col-sm-3 ml-4"></small>
            <small *ngIf="uploadForm.get('livedate').hasError('required') && uploadForm.get('livedate').touched"
              class="form-text text-muted col-sm-8">{{'please
              select date' |translate}}</small>
          </small>

        </div>

        <div class="form-group">
          <div class="row">
            <label class="required col-sm-3 ml-4" for="File">{{'Select File'|translate}}</label>
            <input type="file" formControlName="file" style="height: auto; width: 100%;"
              class=" btn btn-outline-danger col-sm-8" [(ngModel)]="selectedFilePath" (change)="onFileChange($event)"
              accept=".doc,.xml,.txt" />
          </div>
          <small class="row">
            <small class="col-sm-3 ml-4"></small>
            <small *ngIf="invalid && errorTxt === false && fileSizeExceeded == false"
              class="form-text text-muted col-sm-8">{{'Unsupported file format. Please upload an XML
              file!'|translate}}</small>
          </small>
          <small class="row">
            <small class="col-sm-3 ml-4"></small>
            <small *ngIf="uploadForm.get('file').hasError('required') && uploadForm.get('file').touched"
              class="form-text text-muted col-sm-8">{{'Please
              select file'|translate}}</small>
          </small>

          <small class="row">
            <small class="col-sm-3 ml-4"></small>
            <small *ngIf="invalid && errorTxt === false && fileSizeExceeded == true"
              class="form-text text-muted col-sm-8">{{'File exceeds the 5 MB size limit!'|translate}}</small>
          </small>

        </div>

        <div class="form-group ">
          <div class="row">
            <label class="required col-sm-3 ml-4" for="code">{{'Ac Code'|translate}}</label>
            <mat-form-field class="col-sm-8">
              <mat-select placeholder="{{'Select Ac code'|translate}}" formControlName="acCode">
                <mat-select-filter [array]="variables" (filteredReturn)="filteredList1 =$event"></mat-select-filter>
                <mat-option *ngFor="let item of filteredList1" [value]="item"
                  (onSelectionChange)="onChangeCode($event,item)">
                  {{item}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="uploadForm.get('acCode').hasError('required') && uploadForm.get('acCode').touched">{{'Ac
                ccode is required!'|translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="required col-sm-3 ml-4" for="language">{{'Language'|translate}}</label>
            <select class="form-control col-sm-8" formControlName="language" name="language">
              <option selected value="">{{'Choose Option...'|translate}}</option>
              <option value="EN">EN</option>
              <option value="FR">FR</option>
              <option value="NL">NL</option>
            </select>
          </div>
          <small class="row">
            <small class="col-sm-3 ml-4"></small>
            <small *ngIf="uploadForm.get('language').hasError('required') && uploadForm.get('language').touched"
              class="form-text text-muted col-sm-8">{{'please
              select language'|translate}}</small>
          </small>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-sm-3 ml-4" for="lockername">{{'Locker Name'|translate}}</label>
            <input type="text" class="form-control col-sm-8" formControlName="lockerName" id="acCode" readonly
              placeholder="{{'Locker Name'|translate}}">
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-sm-3 ml-4" for="lockerPostalCode">{{'Postal Code'|translate}}</label>
            <input type="text" class="form-control col-sm-8" formControlName="lockerPostalCode" id="acCode" readonly
              placeholder="{{'Postal Code'|translate}}">
          </div>
        </div>
        <div class="text-center pb-4">
          <button class="btn btn-danger" type="Submit">{{'Upload'|translate}}</button>
        </div>
      </form>

    </div>
  </div>
  <app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>