import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { RedirectRequestHandler } from '@openid/appauth';
import { AuthorizationService } from '../../../Service/authorization.service';
import { AuthenticationService } from '../../../Service/authenticaion.service';
import { AppRoutingModule } from '../../../app-routing.module';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationConfigExternal } from '../../../Service/authorization_config_external';
import * as jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-ping-external',
  templateUrl: './ping-external.component.html',
  styleUrls: ['./ping-external.component.scss']
})
export class PingExternalComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    public AuthenticationService: AuthenticationService,
    public router: Router,
    @Inject('AuthorizationConfigExternal') private pingEnvironment: AuthorizationConfigExternal
  ) { }

  ngOnInit(): void {
    let code = this.activatedRoute.snapshot.queryParams.code
    if(code) {
      this.AuthenticationService.handleCode();
    }
  }

  // ngAfterViewInit() {
  //   if (this.activatedRoute.snapshot.queryParams.code) {
  //     let code = this.activatedRoute.snapshot.queryParams.code
  //     if(code) {
  //       this.authorizationService.handleCode();
  //     }
  //     window.localStorage.setItem('authorization_code', code);
  //     let res = this.authorizationService.completeCodeAuthorization('lockerexternal', this.pingEnvironment.redirect_uri, code)
  //     console.log('res', res);
  //     window.localStorage.setItem('userData', JSON.stringify(res));
  //     // this.router.navigate(['dashboard']);
  //     // this.router.navigate(['lockerlist']);
  //   } else {
  //     this.router.navigate(['/']);
  //   }
  // }

  signOut(){
    let token = window.localStorage.getItem('token')
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.href = this.pingEnvironment.pingLogout + 'id_token='+token+`&TargetResource=` + this.pingEnvironment.redirect_uri + `&client_id=lockerexternal`
  }

}
