<app-header></app-header>
<app-loader *ngIf="showSpinner"></app-loader>
<app-lockers-menu [id]="id"></app-lockers-menu>
<br>
<form class="pl-4 pr-4" [formGroup]="DpSettingsForm" (ngSubmit)="onSubmit()">
  <div class="flex items-center justify-start">
    <input type="checkbox" id="checkbox" class="mr-3 checkbox" formControlName="lidl_image">
    <label for="checkbox" style="font-size: large;">Lidl_image</label>
    <br>
      <button type="submit" class="btn btn-danger" >{{'Save'|translate}}</button>
  </div>
</form>
