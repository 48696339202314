<app-header></app-header>
<img src="/assets/img/bg3.jpg" alt="" class="bg2">
<div [appViewController]="{staff:'postmanCode'}">
<div>
    <form [formGroup]="postmanLoginForm" class="container mat-card mb-6 mt-4 shadow bg-white rounded">
        <br>
        <p class="h4" style="text-align: center; margin-top: 10px; margin-bottom: 18px;">{{'Staff Login Code'|translate}}</p>
        <div class="form-group ">
            <div class="row">
                <label class="required col-sm-3 ml-4" for="Capabilities">{{'Capabilities'|translate}}</label>
                <app-multi-select-dropdown [list]="list" class="col-sm-8" style="padding: 0px;"
                    (shareCheckedList)="shareCheckedList($event)"
                    (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
                </app-multi-select-dropdown>
            </div>
            <small class="row">
                <small class="col-sm-3 ml-4"></small>
                <small *ngIf="!dropSuccess" class="form-text text-muted col-sm-8">{{'Please select Capabilities'|translate}}</small>
            </small>
        </div>

        <div class="form-group">
            <div class="row">
                <label class="required col-sm-3 ml-4" for="Name">{{'Name'|translate}}</label>
                <input type="text" formControlName="name" class="form-control col-sm-8" id="Name"
                    aria-describedby="nameHelp" placeholder="{{'Enter Name'|translate}}">
            </div>
            <small class="row">
                <small class="col-sm-3 ml-4"></small>
                <small *ngIf="submitted &&  formValidators.name.errors?.required"
                    class="form-text text-muted col-sm-8">{{'Please Fill Name Field'|translate}}</small>
            </small>
        </div>
        <div class="form-group">
            <div class="row">
                <label class="required col-sm-3 ml-4" for="matriculeID">{{'Matricule ID'|translate}}</label>
                <input type="text" formControlName="matriculeID" class="form-control col-sm-8" 
                id="matriculeID" placeholder="{{'Matricule ID'|translate}}"
                #matriculeID (input) ="matriculeID.value = matriculeID.value.toUpperCase()"
                 pattern="[A-Za-z0-9]*">
            </div>
            <small class="row">
                <small class="col-sm-3 ml-4"></small>
                <small *ngIf="submitted &&  formValidators.matriculeID.errors?.required"
                    class="form-text text-muted col-sm-8">{{'Please Fill Matricule ID'|translate}}</small>
                <small *ngIf="postmanLoginForm.controls.matriculeID.errors?.pattern"class="form-text text-muted col-sm-8"style="color: red;">
                {{ 'Matricule ID must be alphanumeric' | translate }}</small>
            </small>
        </div>

        <div class="form-group">
            <div class="row">
                <label class="required col-sm-3 ml-4" >{{'Language'|translate}}</label>
                <div class="form-group col-sm-8 pl-0 pr-0">
                    <select name="language" class="form-control" formControlName='language'>
                        <option value="" disabled selected>{{'Choose an option' | translate}}</option>
                        <option *ngFor="let item of languageList"
                            [value]="item.value"
                            class="text-capitalize">{{item.label}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <label class="required col-sm-3 ml-4" for="dpId">{{'Delivery Points'|translate}}</label>
                <div class="form-group col-sm-8 pl-0 pr-0">
                    <ng-multiselect-dropdown class="form-control-sm p-0" formControlName="dpId"
                    [placeholder]="'-All-'|translate" [settings]="lockerDropdownSettings" [data]="dpList"
                    [(ngModel)]="lockerSelectedItems" (onSelect)="onItemSelect($event)"
                    (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <small class="row">
                <small class="col-sm-3 ml-4"></small>
                <small *ngIf="submitted &&  formValidators.dpId.errors?.required"
                    class="form-text text-muted col-sm-8">{{'Please select Delivery points'|translate}}</small>
            </small>
        </div>

        <!-- <button type="submit" class="btn btn-secondary" (click)="close()">Close</button> -->
        <div class="text-center">
            <!-- <button type="button" class="btn btn-primary mb-4" (click)="submitData()">Submit</button> -->
            <button class="btn btn-danger mb-4" (click)="submitData()"
                   >{{'Submit'|translate}}</button>
        </div>
    </form>
</div>
<!-- <div class="text-center">
    <button type="button" class="btn btn-primary mb-4" (click)="exportFun()">export</button>
</div> -->
<div *ngIf="tableData && tableData.length > 0"  class="mat-card container">
<table class="table">
    <caption></caption>
    <thead>
      <tr>
        <th scope="col">{{'AC Code'|translate}}</th>
        <th scope="col">{{'Code'|translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of tableData">
        <td>{{data.acCode}}</td>
        <td>{{data.code || '-'}}</td>
      </tr>
    </tbody>
  </table>
</div>
</div>
<!-- <div *ngIf="!isMoreThanZero"  class="mat-card container">
    <p>No Data Found</p>
  </div> -->
