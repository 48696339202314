import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../../Service/partner.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as FileSaver from 'file-saver';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from '../../../Service/app.service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import *as Papa from 'papaparse';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.scss'],
})
export class PartnerListComponent implements OnDestroy, OnInit {
  partnersList: any;
  dtOptions: DataTables.Settings = {};
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  lockerFetchfail: any;
  translateData: any;
  filterForm: FormGroup;
  deleteId: any;
  partnerName: any;
  bussinessPartnerSettings: any;
  submitted: boolean;
  selectedPartnerIds = [];
  @ViewChild('partnerEdit', { static: false }) partnerEdit: ElementRef;
  ids = { "partnerId": [] };
  FilterPartnersList: any
  AcCode: any;
  LockerName: any;
  PartnerName: any;
  LockerPostalCode: any;
  LockerAddress: any;
  LockerMunicipality: any;
  LockerType: any;
  LockerStatus: any;
  showEdit: boolean = false

  constructor(
    private langtransService: LangtransService,
    private appService: AppService,
    public router: Router,
    private toastr: ToastrService,
    private partnerService: PartnerService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private spinner: SpinnerService
  ) {
    this.filterForm = this.formBuilder.group({
      partnerName: ['']
    })
  }

  ngOnInit(): void {
    this.appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data
      this.http.get(`/assets/i18n/${data}.json`).subscribe((data: any) => {
        this.AcCode = data.AcCode
        this.PartnerName = data.PartnerName
        this.LockerName = data.LockerName
        this.LockerAddress = data.LockerAddress
        this.LockerType = data.LockerType
        this.LockerMunicipality = data.LockerMunicipality
        this.LockerStatus = data.LockerStatus
        this.LockerPostalCode = data.LockerPostalCode
      })
    })
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      destroy: true,
    };
    this.getPartnerDropdown()
    this.getPartnersList(this.ids);
    this.bussinessPartnerSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: true
    }
  }

  ngAfterViewInit() {
    let partneredit = this.partnerEdit.nativeElement;
    let partnerstyle = window.getComputedStyle(partneredit)
    let partnerDisplay = partnerstyle?.display;
    if (partnerDisplay == 'none') {
      this.showEdit = true
    }
    else {
      this.showEdit = false
    }
  }

  downloadAll() {
    this.spinner.show();
    let PartnerReport
    this.partnerService.downloadAll(this.ids)
      .subscribe((receivedFile: any) => {

        // const source = `data:text/xlsx;base64,${await receivedFile.text()}`;
        // const link = document.createElement("a");
        // link.href = source;
        // const Today = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
        // let downtimeReport = 'Partners_' + Today + '.xlsx'
        // link.download = downtimeReport
        // link.click();
        const csvData = this.formatDataForCSV(receivedFile)
        //console.log(csvData, "csvData")
        const Today = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
        //let PartnerReport = 'Partners_' + Today + '.csv'
        if (this.ids && this.ids.partnerId.length == 1) {
          let str = receivedFile[0].name;
          let partnerNamestr = str.replace(/ /g, "_");
          PartnerReport = `partner_overview_${partnerNamestr}_${Today}`
        } else {
          PartnerReport = `partner_overview_all_${Today}`
        }
        this.downloadCSV(csvData, PartnerReport)
      }, (error) => {
        console.error(error)
        this.langaugeFetch(this.translateData, 'Errordownloadingthefile')
      })
  }

  formatDataForCSV(data: any[]): string {
    const flattenData = data.map((item) => {
      return [
        item.name,
        item.lockerName,
        item.lockerAcCode,
        item.lockerPostalCode,
        item.lockerStreetName,
        item.lockerMunicipality,
        item.lockerType,
        item.lockerOperationActive
      ];
    });
    const headers = [this.PartnerName, this.LockerName, this.AcCode, this.LockerPostalCode, this.LockerAddress, this.LockerMunicipality, this.LockerType, this.LockerStatus]
    const csvContent = Papa.unparse({ fields: headers, data: flattenData }, { quotes: true });
    return csvContent;
  }


  downloadCSV(data: string, filename: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    this.spinner.hide();
  }

  langaugeFetch(lang, key) {
    this.langtransService.TranslateData(lang, key).subscribe(
      res => {
        this.toastr.error(res)
      }
    )
  }

  getPartnerDropdown() {
    this.partnerService.fetchPartnersList().subscribe(
      res => {
        this.FilterPartnersList = res
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getPartnersList(partnerIds) {
    this.spinner.show();
    // if (partnerIds.partnerId == undefined) {
    this.partnerService.fetchAndFilterPartnersList(partnerIds).subscribe(
      res => {
        this.partnersList = res
        if (this.partnersList.length === 0) {
          //this.toastr.error('Failed to fetch partner list !!', 'Failed');
          this.langaugeFetch(this.translateData, 'Failedtofetchpartnerlist')
        }
        this.spinner.hide();
        this.dtTrigger.next();
      },
      (err) => {
        this.spinner.hide();
        //this.toastr.error('Failed to fetch partner list !!', 'Failed');
        this.langaugeFetch(this.translateData, 'Failedtofetchpartnerlist')
        console.error(err);
      }
    );
  }


  get formValidators() {
    return this.filterForm.controls
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }

  onSubmit() {
    this.submitted = true
    this.selectedPartnerIds = []
    if (this.filterForm.valid) {
      // console.log(this.filterForm.controls.partnerName.value, "selected item")
      let selectedPartners = this.filterForm.controls.partnerName.value
      for (let i = 0; i < selectedPartners.length; i++) {
        this.selectedPartnerIds.push(selectedPartners[i].id)
      }
      // console.log(this.selectedPartnerIds, "selectedPartnerIds")
      this.ids = { "partnerId": this.selectedPartnerIds }
      let partnerIds = { "partnerId": this.selectedPartnerIds }
      this.getPartnersList(partnerIds)
      this.rerender();
    }
  }

  onReset() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  onEdit(partner) {
    this.router.navigate(['/partners/', partner.id]);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
