<app-header></app-header>
<img src="/assets/img/bg3.jpg" alt="" class="bg2">
<br>
<br>
<br>
<div>
  <form [formGroup]="userDetailsForm" class="pl-4 pr-4">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mx-auto">
      <div class="card shadow p-3 mb-5 bg-white rounded" style="width: 100%; margin: 10px;">
        
        <div class="card-body p-3">
          <div>
            <div class="col text-right">
              <h6 class=" card-title col text-center font-weight-bold " style="font-size: 15px;">{{'EDIT USER DETAILS'|translate}}</h6>
            </div>

          </div>
          <div class="col-12 text-secondary">
            <hr>

            <!-- <div class="row mt-3">
              <div class="col-6 pr-0">{{'ID'|translate}}</div>
              <div class="col-6 text-right pl-0">
                <input type="text" class="form-control" formControlName="id" id="id" placeholder="{{'ID'|translate}}" readonly>

              </div>
            </div> -->
            <hr>
            <div class="row mt-3 ">
              <div class="col-6 pr-0">{{'First name'|translate}}</div>
              <div class="col-6 text-right pl-0">
                <input type="text" class="form-control" formControlName="firstName" id="firstName" placeholder="{{'enter first name'|translate}}">
                <span class="help-block">
                  <span *ngIf="submitted && userDetailsForm.controls.firstName.errors">
                      <div *ngIf="userDetailsForm.controls.firstName.errors.required"
                          class="error-text text-danger mt-1">{{'FirstName is required'|translate}}</div>
                  </span>
              </span>
              </div>
            </div>
            

            <hr>
            <div class="row mt-3 ">
              <div class="col-6 pr-0">{{'Last name'|translate}}</div>
              <div class="col-6 text-right pl-0">
                <input type="text" class="form-control" formControlName="lastName" id="lastName"  placeholder="{{'enter last name'|translate}}">
                <span class="help-block">
                  <span *ngIf="submitted && userDetailsForm.controls.lastName.errors">
                      <div *ngIf="userDetailsForm.controls.lastName.errors.required"
                          class="error-text text-danger mt-1">{{'LastName is required'|translate}}</div>
                  </span>
              </span>
              </div>
            </div>
            
            <hr>
            <div class="row mt-3 ">
              <div class="col-6 pr-0">E-mail</div>
              <div class="col-6 text-right pl-0">
                <input type="text" class="form-control" formControlName="Email" id="Email" placeholder="{{'Email'|translate}}" readonly>
                
              </div>
            </div>
            <span class="help-block">
              <span *ngIf="submitted && userDetailsForm.controls.Email.errors">
                  <div *ngIf="userDetailsForm.controls.Email.errors.required"
                      class="error-text text-danger mt-1">{{'email is required'|translate}}</div>
                  <div *ngIf="userDetailsForm.controls.Email.errors.pattern"
                      class="error-text text-danger mt-1">{{'please enter a valid email address'|translate}}</div>
              </span>
            </span>
            <hr>
            <div class="row mt-3 ">
              <div class="col-6 pr-0">{{'Mobile number'|translate}}</div>
              <div class="col-6 text-right pl-0">
                <input type="text" class="form-control" formControlName="mobileNumber" id="mobileNumber"
                  placeholder="{{'Mobile number'|translate}}"  maxlength="15" (keypress)="restrictAlphabets($event)">
                  <span class="help-block">
                    <span *ngIf="submitted && userDetailsForm.controls.mobileNumber.errors">
                        <div *ngIf="userDetailsForm.controls.mobileNumber.errors.pattern"
                            class="error-text text-danger mt-1">{{'please enter a valid mobile number'|translate}}</div>
                    </span>
                  </span>
               </div>
            </div>
            
            <hr>
            <div class="row mt-3">
              <div class="col-6 pr-0">{{'Groups'|translate}}</div>
              <div class="col-6 text-right pl-0">
                <ng-multiselect-dropdown formControlName="Groups" id="Groups" [placeholder]="'-All-'|translate"
                  [settings]="userGroupdropdownSettings" [data]="userGroupdropdownList"
                  [(ngModel)]="userGroupselectedItems" (onSelect)="onItemSelect($event)"
                  (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
                <span class="help-block">
                  <span *ngIf="submitted && userDetailsForm.controls.Groups.errors">
                      <div *ngIf="userDetailsForm.controls.Groups.errors.required"
                          class="error-text text-danger mt-1">
                          {{'Group is required'|translate}}
                      </div>
                  </span>
              </span>
              </div>
            </div>
            
          <hr>
            <div class="row mt-3">
              <div class="col-6 pr-0">{{'Partner'|translate}}</div>
              <div class="col-6 text-right pl-0">
                <input type="text" class="form-control" formControlName="partner" id="partner" placeholder="{{'Partner'|translate}}" readonly>

              </div>
            </div>
            <hr>
            <div class="row mt-3 ">
              <div class="col-6 pr-0">{{'Active'|translate}}</div>
              <div class="col-6 text-right pl-0">
                <input class="custom-checkbox"  type="checkbox" formControlName="active" id="active" >
               </div>
              
            </div>
            <hr>
            <div class="text-center">
              <button  type="button" class="btn btn-danger mr-3 mt-2"
              (click)="formFieldsValidate()">{{'Save'|translate}}</button>
              <button class="btn btn-outline-danger ml-3 mt-2" (click)="cancel()">{{'Cancel'|translate}}</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="modal fade" tabindex="-1" id="editUserModal" role="dialog" aria-labelledby="editUserModal"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{'EDIT USER'|translate}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{'Are you sure you want to edit the user details'|translate}}?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Cancel'|translate}}</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onSave()">{{'Confirm'|translate}}</button>
        </div>
      </div>
    </div>
  </div>

</div>
